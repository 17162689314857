import React from "react";
import ScheduleTableItem from "./ScheduleTableItem";
// import PropTypes from 'prop-types'

class ScheduleTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scheduleItemNodes: [],
    };
  }
  static getDerivedStateFromProps(props, state) {
    const date = props.date;
    const days = [
      new Date(date),
      new Date(date),
      new Date(date),
      new Date(date),
    ];
    days[1] = new Date(days[1].setDate(days[1].getDate() + 1));
    days[2] = new Date(days[2].setDate(days[2].getDate() + 2));
    days[3] = new Date(days[3].setDate(days[3].getDate() + 3));
    days.forEach((e) => e.setHours(0, 0, 0, 0));
    if (window.innerWidth <= 625) {
      return {
        scheduleItemNodes: [
          <ScheduleTableItem changeDate={props.changeDate} date={days[0]} />,
        ],
      };
    } else if (window.innerWidth <= 970) {
      return {
        scheduleItemNodes: [
          <ScheduleTableItem changeDate={props.changeDate} date={days[0]} />,
          <ScheduleTableItem changeDate={props.changeDate} date={days[1]} />,
        ],
      };
    } else if (window.innerWidth <= 1220) {
      return {
        scheduleItemNodes: [
          <ScheduleTableItem changeDate={props.changeDate} date={days[0]} />,
          <ScheduleTableItem changeDate={props.changeDate} date={days[1]} />,
          <ScheduleTableItem changeDate={props.changeDate} date={days[2]} />,
        ],
      };
    } else if (window.innerWidth > 1220) {
      return {
        scheduleItemNodes: [
          <ScheduleTableItem changeDate={props.changeDate} date={days[0]} />,
          <ScheduleTableItem changeDate={props.changeDate} date={days[1]} />,
          <ScheduleTableItem changeDate={props.changeDate} date={days[2]} />,
          <ScheduleTableItem changeDate={props.changeDate} date={days[3]} />,
        ],
      };
    }
  }
  componentDidMount() {}
  render() {
    const { workoutType } = this.props;
    return (
      <div className="schedule-dashboard__table">
        {this.state.scheduleItemNodes &&
          this.state.scheduleItemNodes.map((e, i) => (
            <e.type {...e.props} key={`${e};${i}`} workoutType={workoutType} />
          ))}
      </div>
    );
  }
}

ScheduleTable.propTypes = {};

export default ScheduleTable;
