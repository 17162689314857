import React from "react";
// import PropTypes from "prop-types";

const PaymentsPage = props => {
  return (
    <div>
      <h3>Prochainement...</h3>
      <p>
        Vous pourrez gérer tous les paiements de vos adhérents via cette page
      </p>
    </div>
  );
};

PaymentsPage.propTypes = {};

export default PaymentsPage;
