import React from "react";
import ScheduleTable from "../schedule/ScheduleTable";
import ScheduleTableHeaderControl from "./ScheduleTableHeaderControl";
import { openModalAction } from "../../../redux/modals/modalsActions";
import NewReservationModal from "../modals/schedule/NewReservationModal";
import { connect } from "react-redux";

class ScheduleDashboard extends React.Component {
  constructor(props) {
    const todaysDate = new Date();
    let endDisplayDate = new Date();
    if (window.innerWidth <= 575) {
      endDisplayDate = todaysDate;
    } else if (window.innerWidth <= 900) {
      endDisplayDate.setDate(endDisplayDate.getDate() + 1);
    } else if (window.innerWidth <= 1199) {
      endDisplayDate.setDate(endDisplayDate.getDate() + 2);
    } else if (window.innerWidth > 1199) {
      endDisplayDate.setDate(endDisplayDate.getDate() + 3);
    }
    super(props);
    this.state = {
      date: todaysDate,
      endDisplayDate,
      workoutSelected: "wod",
    };
  }
  changeWorkoutType = (e) => {
    this.setState({ workoutSelected: e.target.value });
  };
  changeDate = (target) => {
    if (target === "add") {
      const newDate = this.state.date.setDate(this.state.date.getDate() + 1);
      const newEndDate = this.state.endDisplayDate.setDate(
        this.state.endDisplayDate.getDate() + 1
      );
      this.setState(() => ({
        date: new Date(newDate),
        endDisplayDate: new Date(newEndDate),
      }));
    } else if (target === "remove") {
      const newDate = this.state.date.setDate(this.state.date.getDate() - 1);
      const newEndDate = this.state.endDisplayDate.setDate(
        this.state.endDisplayDate.getDate() - 1
      );
      this.setState(() => ({
        date: new Date(newDate),
        endDisplayDate: new Date(newEndDate),
      }));
    }
  };
  render() {
    const { openModalAction, user, workoutSchedule, loading } = this.props;
    const modal = {
      modalHeader: "Nouvelle Réservation",
      modalComponent: <NewReservationModal date={new Date()} />,
      modalType: "confirm",
    };

    return (
      <React.Fragment>
        {user && user.profileType === "box" && <h3>Réservations</h3>}
        <ScheduleTableHeaderControl
          date={this.state.date}
          endDate={this.state.endDisplayDate}
          changeDate={this.changeDate}
        />
        {user && user.profileType === "athlete" ? (
          <button
            className="new-reservation__button"
            onClick={() => openModalAction(modal)}
          >
            <i className="fal fa-calendar-plus"></i>
          </button>
        ) : (
          <select
            name="wodType"
            onChange={this.changeWorkoutType}
            className="workout-selection__select"
          >
            <option value="wod">WOD</option>
            {workoutSchedule &&
              Object.keys(workoutSchedule).map((e, i) => {
                let isEmpty = true;
                Object.keys(workoutSchedule[e].times).map((t) => {
                  if (workoutSchedule[e].times[t].length !== 0) {
                    isEmpty = false;
                  }
                  return null;
                });
                if (e === "wod") {
                  return null;
                } else {
                  if (isEmpty === false) {
                    return (
                      <option value={e} key={i}>
                        {workoutSchedule[e].workoutName}
                      </option>
                    );
                  } else {
                    return null;
                  }
                }
              })}
          </select>
        )}
        {loading ? (
          <div className="loader"></div>
        ) : (
          <ScheduleTable
            date={this.state.date}
            changeDate={this.changeDate}
            workoutType={this.state.workoutSelected}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapState = (state, props) => ({
  user: state.user,
  workoutSchedule: state.schedule.workoutSchedule,
  loading: state.async.loading,
});

const mapActions = {
  openModalAction,
};

export default connect(mapState, mapActions)(ScheduleDashboard);
