import React from "react";
import ScheduleItem from "../../schedule/ScheduleItem";
// import PropTypes from 'prop-types'
import { connect } from "react-redux";
import { openModalAction } from "../../../../redux/modals/modalsActions";
import ReservationConfirmationModal from "./ReservationConfirmationModal";
import {
  newReservationAction,
  addUserToReservationAction,
} from "../../../../redux/schedule/scheduleActions";

// import { Formik, Form, Field } from "formik";¨

class NewReservationSelector extends React.Component {
  state = {
    wodType: "wod",
    assigned: false,
    loaded: false,
  };
  changeWorkoutType = (e) => {
    this.setState({ wodType: e.target.value });
  };

  render() {
    const {
      date,
      openModalAction,
      activeReservations,
      workoutSchedule,
      loading,
      newReservationAction,
      userId,
      addUserToReservationAction,
    } = this.props;
    const modal = {
      modalHeader: "Confirmer la réservation",
      modalType: "confirm",
    };

    const todaysDate = {
      day: date.getDay(),
      date,
    };
    return (
      <React.Fragment>
        <select name="wodType" onChange={this.changeWorkoutType}>
          <option value="wod">WOD</option>
          {workoutSchedule &&
            Object.keys(workoutSchedule).map((e, i) => {
              let isEmpty = true;
              Object.keys(workoutSchedule[e].times).map((t) => {
                if (workoutSchedule[e].times[t].length !== 0) {
                  isEmpty = false;
                }
                return null;
              });
              if (e === "wod") {
                return null;
              } else {
                if (isEmpty === false) {
                  return (
                    <option value={e} key={i}>
                      {workoutSchedule[e].workoutName}
                    </option>
                  );
                } else {
                  return null;
                }
              }
            })}
        </select>

        {loading ? (
          <div className="loader"></div>
        ) : (
          <div className="new-wod__list">
            {workoutSchedule &&
              Object.keys(workoutSchedule).map((workoutType, i) => {
                if (workoutType === this.state.wodType) {
                  return workoutSchedule[workoutType].times[todaysDate.day].map(
                    (e, i) => {
                      // Check if the reservation already exists
                      // Step 1 : Get reservation time
                      const hours = e.substring(0, 2);
                      const minute = e.substring(2, 4);
                      const dateTime = todaysDate.date.setHours(
                        hours,
                        minute,
                        0,
                        0
                      );
                      const workoutDate = new Date(dateTime);
                      let activeResa;
                      if (workoutDate < new Date()) {
                        return null;
                      } else if (activeReservations) {
                        activeReservations.map((resa) => {
                          let dateZero = new Date(0);
                          dateZero.setUTCSeconds(resa.date._seconds);

                          if (
                            workoutType === resa.workoutType &&
                            workoutDate.toLocaleString("fr") ===
                              dateZero.toLocaleString("fr")
                          ) {
                            console.log(resa);
                            activeResa = resa;
                            return resa;
                          } else return null;
                        });
                      }
                      return (
                        <ScheduleItem
                          key={i}
                          wodType={workoutType}
                          wodName={workoutSchedule[workoutType].workoutName}
                          date={workoutDate.toLocaleString("fr")}
                          resa={activeResa}
                          click={() =>
                            openModalAction({
                              ...modal,
                              modalComponent: (
                                <ReservationConfirmationModal
                                  activeReservation={activeResa}
                                  reservation={{
                                    reservationDate:
                                      workoutDate.toLocaleString("fr"),
                                    e: {
                                      workoutType,
                                      workoutName:
                                        workoutSchedule[workoutType]
                                          .workoutName,
                                    },
                                  }}
                                  date={date}
                                />
                              ),
                              confirmAction: () => {
                                if (activeResa) {
                                  addUserToReservationAction(
                                    activeResa.reservationId,
                                    userId,
                                    true
                                  );
                                } else {
                                  newReservationAction({
                                    date: workoutDate,
                                    workoutType: workoutType,
                                    workoutName:
                                      workoutSchedule[workoutType].workoutName,
                                    capacity:
                                      workoutSchedule[workoutType].capacity,
                                    waitlistCapacity:
                                      workoutSchedule[workoutType]
                                        .waitlistCapacity,
                                  });
                                }
                              },
                            })
                          }
                        />
                      );
                    }
                  );
                } else {
                  return null;
                }
              })}
          </div>
        )}
      </React.Fragment>
    );
  }
}

NewReservationSelector.propTypes = {};

const mapState = (state, props) => ({
  userId: state.user.userId,
  workoutSchedule: state.schedule.workoutSchedule,
  activeReservations: state.schedule.activeReservations,
  loading: state.async.loading,
});

const mapActions = {
  openModalAction,
  newReservationAction,
  addUserToReservationAction,
};

export default connect(mapState, mapActions)(NewReservationSelector);
