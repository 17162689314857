import React from "react";
// import PropTypes from "prop-types";
import { connect } from "react-redux";
import { deleteUserFromReservationAction } from "../../../redux/schedule/scheduleActions";
import { closeModalAction } from "../../../redux/modals/modalsActions";

const ReservationMembersItem = ({
  resa,
  member,
  action,
  click,
  closeModalAction,
  checked,
}) => {
  const { firstName, lastName } = member;
  return (
    <React.Fragment>
      {member && resa && (
        <div className={`reservation-details__member${action && `-${action}`}`}>
          <span>{`${firstName} ${lastName}`}</span>
          <button
            onClick={async () => {
              if (action && action !== "check") {
                click();
              }
            }}
          >
            {action && action === "delete" && <i className="fal fa-times"></i>}
            {action && action === "add" && <i className="fal fa-plus"></i>}
            {action && action === "check" && <i className="fal fa-check"></i>}
          </button>
        </div>
      )}
    </React.Fragment>
  );
};

ReservationMembersItem.propTypes = {};

const mapState = (state, props) => ({});

const mapActions = {
  deleteUserFromReservationAction,
  closeModalAction,
};

export default connect(mapState, mapActions)(ReservationMembersItem);
