export const monthConverter = a => {
  switch (a) {
    case "01":
      return "Jan.";
    case "02":
      return "Fév.";
    case "03":
      return "Mar.";
    case "04":
      return "Avr.";
    case "05":
      return "Mai";
    case "06":
      return "Jun.";
    case "07":
      return "Jul.";
    case "08":
      return "Aou.";
    case "09":
      return "Sep.";
    case "10":
      return "Oct.";
    case "11":
      return "Nov.";
    case "12":
      return "Déc.";
    default:
      break;
  }
};
