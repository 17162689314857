import React from "react";
// import PropTypes from "prop-types";

const WodBuilder = props => {
  return (
    <div>
      <h3>Prochainement...</h3>
      <p>
        Vous aurez accès à un outil dédié à la programmation pour pouvoir créer
        des entrainements équilibrés.
      </p>
    </div>
  );
};

WodBuilder.propTypes = {};

export default WodBuilder;
