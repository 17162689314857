import React from "react";
// import PropTypes from "prop-types";
import { Formik, Field, Form, FieldArray } from "formik";
import { connect } from "react-redux";
import StarRating from "./StarRating";
import LogDetails from "../modals/log/LogDetails";
import { openModalAction } from "../../../redux/modals/modalsActions";
import { createLogWorkoutAction } from "../../../redux/log/logActions";

const initialValues = {
  date: new Date().toISOString().substr(0, 10),
  secondsToComp: 0,
  exercises: [{ movement: "", reps: "", weight: "" }],
  workoutRating: 0
};

const LogForm = ({ openModalAction, createLogWorkoutAction, onCloseForm }) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => {
        const workoutDate = new Date(values.date);
        const modal = {
          modalHeader: `Entrainement du ${workoutDate.toLocaleDateString(
            "fr"
          )}`,
          modalComponent: (
            <LogDetails logEntry={{ ...values, date: workoutDate }} />
          ),
          confirmAction: () =>
            createLogWorkoutAction(
              { ...values, date: workoutDate },
              onCloseForm
            ),
          modalType: "confirm"
        };
        openModalAction(modal);
      }}
    >
      {props => (
        <div className="log-dashboard__container--form">
          <Form>
            <div className="log-dashboard__container--form-line">
              <div className="log-dashboard__container--form-element">
                <label htmlFor="workoutType">Type d'entrainement</label>
                <br />
                <Field as="select" name="workoutType">
                  <option value="">Choisir entrainement</option>
                  <option value="AMRAP">AMRAP</option>
                  <option value="FT">For Time</option>
                  <option value="FGB">Fight Gone Bad</option>
                  <option value="EMOM">EMOM</option>
                  <option value="DB">Death By</option>
                  <option value="TBT">Tabata</option>
                  <option value="CUSTOM">Custom</option>
                </Field>
              </div>
              <div className="log-dashboard__container--form-element">
                <label htmlFor="date">Date</label>
                <br />
                <Field type="date" name="date" />
              </div>
              <div className="log-dashboard__container--form-element">
                <label htmlFor="nbRounds">Nombre de Rounds</label>
                <br />
                <Field type="number" name="nbRounds" />
              </div>
              <div className="log-dashboard__container--form-element">
                <label htmlFor="timeCap">Time Cap (min)</label>
                <br />
                <Field type="number" name="timeCap" />
              </div>
            </div>
            {/* {props.values.workoutType === 'EMOM' ?} */}
            <FieldArray
              name="exercises"
              render={arrayHelpers => (
                <div>
                  {props.values.exercises &&
                    props.values.exercises.map((exercise, index) => (
                      <div
                        className="log-dashboard__container--form-line"
                        key={index}
                      >
                        <div className="log-dashboard__container--form-element">
                          <label htmlFor="movement1">
                            Mouvement {index + 1}
                          </label>
                          <br />
                          <Field
                            type="text"
                            name={`exercises.${index}.movement`}
                          />
                        </div>
                        <div className="log-dashboard__container--form-element">
                          <label htmlFor="reps">Répétitions</label>
                          <br />
                          <Field
                            type="number"
                            name={`exercises.${index}.reps`}
                            placeholder=""
                          />
                        </div>
                        <div className="log-dashboard__container--form-element">
                          <label htmlFor="weight">Poids</label>
                          <br />
                          <Field
                            type="number"
                            name={`exercises.${index}.weight`}
                            placeholder="KG"
                          />
                        </div>
                        <button
                          type="button"
                          className="log-dashboard__container--remove-exercises-button"
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          <i className="fal fa-times-circle"></i>
                        </button>
                      </div>
                    ))}
                  <button
                    className="log-dashboard__container--add-exercises-button"
                    type="button"
                    onClick={() =>
                      arrayHelpers.push({ movement: "", reps: "", weight: "" })
                    }
                  >
                    Ajouter un mouvement
                  </button>
                </div>
              )}
            />
            <div className="log-dashboard__container--form-line">
              <div className="log-dashboard__container--form-element">
                <label htmlFor="repsPerformed">Nombre de reps efféctuées</label>
                <br />
                <Field type="number" name="repsPerformed" placeholder="" />
              </div>
              <div className="log-dashboard__container--form-element">
                <label htmlFor="minutesToComp">Temps de complétion</label>
                <br />
                <Field
                  type="number"
                  name="minutesToComp"
                  placeholder="Minutes"
                />
                <Field
                  type="number"
                  name="secondsToComp"
                  placeholder="Secondes"
                />
              </div>
            </div>
            <div className="log-dashboard__container--form-line">
              <div className="log-dashboard__container--form-element--checkbox">
                <label htmlFor="isRx">RX ?</label>
                <Field type="checkbox" name="isRx" />
              </div>
              <div className="log-dashboard__container--form-element">
                <label htmlFor="starRating">Note de l'entrainement</label>
                <br />
                <Field name="workoutrating" component={StarRating} />
              </div>
            </div>
            <div className="log-dashboard__container--form-line">
              <div className="log-dashboard__container--form-element--textarea">
                <label htmlFor="comments">Commentaires</label>
                <br />
                <Field
                  as="textarea"
                  name="comments"
                  id="comments-textarea"
                  placeholder=""
                />
              </div>
            </div>
            <button
              className="log-dashboard__container--form-button"
              type="submit"
            >
              CONFIRMER
            </button>
          </Form>
        </div>
      )}
    </Formik>
  );
};

LogForm.propTypes = {};

const mapState = (state, props) => ({});

const mapActions = {
  openModalAction,
  createLogWorkoutAction
};

export default connect(mapState, mapActions)(LogForm);
