import { GET_USER_DETAILS, REMOVE_USER_DETAILS } from "./userConstants";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { closeModalAction } from "../modals/modalsActions";
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from "../async/asyncActions";

export const getUserAction = () => async (dispatch, getState) => {
  try {
    const res = await axios.get("/user");
    dispatch({ type: GET_USER_DETAILS, payload: res.data });
  } catch (error) {
    console.log({ ...error });
  }
};

export const removeUserAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: REMOVE_USER_DETAILS });
  } catch (error) {
    console.log({ ...error });
  }
};

export const updateUserAction = userData => async (dispatch, getState) => {
  try {
    await axios.post("/user", userData);
    toastr.success("Profil modifié");
    dispatch(getUserAction());
  } catch (error) {
    console.log("Echec de la modification du profil", error.message);
    toastr.error("Échec de la modification du profil");
  }
};

export const uploadUserImageAction = image => async dispatch => {
  try {
    dispatch(asyncActionStart());
    await axios.post("/user/image", image);
    toastr.success("Image de profil modifiée");
    dispatch(closeModalAction());
    dispatch(getUserAction());
    dispatch(asyncActionFinish());
  } catch (error) {
    toastr.error("Échec de la modification de l'image");
    dispatch(asyncActionError());
  }
};
