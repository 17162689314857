import React from "react";
// import PropTypes from "prop-types";

class StarRating extends React.Component {
  state = {
    rating: 0,
    hovered: null
  };
  onHover = ({ target }) => {
    const id = Number(target.id);
    this.setState(() => ({
      hovered: id
    }));
  };
  onLeave = ({ target }) => {
    this.setState(() => ({
      hovered: null
    }));
  };
  onStarClick = ({ target }) => {
    const id = Number(target.id) + 1;
    this.setState(() => ({
      rating: id
    }));
    this.props.form.setFieldValue("workoutRating", id);
  };

  render() {
    const starsNumber = 5;
    const { rating, hovered } = this.state;
    let starsArray = [];
    const selectedStar = "selected-star";
    const hoveredStar = "hovered-star";
    for (let i = 0; i < starsNumber; i += 1) {
      const icon = (hover, selected) => {
        starsArray.push(
          <i
            onMouseEnter={i => this.onHover(i)}
            onMouseLeave={i => this.onLeave(i)}
            onClick={i => this.onStarClick(i)}
            key={i}
            id={i}
            className={`fa fa-star star ${hover} ${selected}`}
          ></i>
        );
      };

      if (rating > i) {
        if (hovered && hovered > i) {
          icon(hoveredStar, selectedStar);
        } else {
          icon(null, selectedStar);
        }
      } else {
        if (hovered && hovered > i) {
          icon(hoveredStar, null);
        } else {
          icon(null, null);
        }
      }
    }
    return <div className="star-rating">{starsArray}</div>;
  }
}

export default StarRating;
