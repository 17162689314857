import { combineReducers } from "redux";
import asyncReducer from "./async/asyncReducer";
import authReducer from "./auth/authReducer";
import respMenuReducer from "./nav/navReducer";
import userReducer from "./user/userReducer";
import { reducer as toastrReducer } from "react-redux-toastr";
import boxMessagesReducer from "./messages/messagesReducer";
import scheduleReducer from "./schedule/scheduleReducer";
import modalReducer from "./modals/modalsReducer";
import workoutLogReducer from "./log/logReducer";
import membersReducer from "./members/membersReducer";
import healthReducer from "./health/healthReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  async: asyncReducer,
  responsive: respMenuReducer,
  user: userReducer,
  toastr: toastrReducer,
  messages: boxMessagesReducer,
  schedule: scheduleReducer,
  modal: modalReducer,
  workoutLog: workoutLogReducer,
  members: membersReducer,
  health: healthReducer
});

export default rootReducer;
