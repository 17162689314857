import {
  GET_BOX_MESSAGES,
  // UPDATE_BOX_MESSAGE,
  DELETE_BOX_MESSAGE,
  NEW_BOX_MESSAGE
} from "./messagesConstants";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from "../async/asyncActions";
// import { toastr } from "react-redux-toastr";

export const getBoxMessagesAction = () => async (dispatch, getState) => {
  try {
    dispatch(asyncActionStart());
    const res = await axios.get("/box-messages");
    dispatch({ type: GET_BOX_MESSAGES, payload: res.data });
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log({ ...error });
    dispatch(asyncActionError);
  }
};

export const newBoxMessagesAction = boxMessage => async (
  dispatch,
  getState
) => {
  console.log("New box message action fired");
  const todaysDate = new Date();
  let message = { ...boxMessage };

  try {
    if (boxMessage.title === "" && boxMessage.body !== "") {
      throw new Error("Veuillez remplir les champs");
    } else if (boxMessage.title === "" && boxMessage.body !== "") {
      message.title = `${boxMessage.body.substring(0, 12)}...`;
    } else if (boxMessage.body === "" && boxMessage.title !== "") {
      message.body = boxMessage.title;
    }
    await axios.post("/box-messages", message);
    message.createdAt = { _seconds: todaysDate.valueOf() / 1000 };
    dispatch({ type: NEW_BOX_MESSAGE, payload: message });
    toastr.success("Message ajouté avec succès");
  } catch (error) {
    toastr.error(
      "Message non ajouté",
      "Veuillez réessayer. Si le problème persiste, contacter un administrateur"
    );
    console.log({ ...error });
  }
};

export const deleteBoxMessagesAction = boxMessageId => async (
  dispatch,
  getState
) => {
  console.log("Delete box message action fired");
  try {
    await axios.delete(`/box-messages/${boxMessageId}`);
    dispatch({ type: DELETE_BOX_MESSAGE, payload: boxMessageId });
    toastr.success("Message supprimé");
  } catch (error) {
    console.log({ ...error });
    toastr.error(
      "Échec de la suppression",
      "Veuillez réessayer. Si le problème persiste, contacter un administrateur"
    );
  }
};
