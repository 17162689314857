import React from "react";
import CalendarDate from "../../dashboard/CalendarDate";
import NewReservationSelector from "./NewReservationSelector";
import { ScheduleTableControlBtn } from "../../dashboard/ScheduleTableHeaderControl";
// import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getFullScheduleAction } from "../../../../redux/schedule/scheduleActions";

class NewReservationModal extends React.Component {
  // Change the date from inside here
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.getFullScheduleAction();
  }
  changeDate = target => {
    const newDate = this.state.date;
    if (target === "add") {
      newDate.setDate(this.state.date.getDate() + 1);
      this.setState(() => ({
        date: new Date(newDate)
      }));
    } else if (target === "remove") {
      newDate.setDate(this.state.date.getDate() - 1);

      this.setState(() => ({
        date: new Date(newDate)
      }));
    }
  };
  static getDerivedStateFromProps(props, state) {
    const date = props.date;
    return { date };
  }
  render() {
    return (
      <div className="schedule-reservation__modal--new">
        <div className="schedule-reservation__modal--left">
          <CalendarDate date={this.state.date} />
          <div className="schedule-reservation__modal--controls">
            <ScheduleTableControlBtn
              iconClass="fal fa-arrow-circle-left"
              supClass="blue-control-icon"
              changeDate={this.changeDate}
            />
            <ScheduleTableControlBtn
              iconClass="fal fa-arrow-circle-right"
              supClass="blue-control-icon"
              changeDate={this.changeDate}
            />
          </div>
        </div>
        <div className="schedule-reservation__modal--right">
          <NewReservationSelector
            key={this.state.date}
            date={this.state.date}
          />
        </div>
      </div>
    );
  }
}

NewReservationModal.propTypes = {};

const mapActions = {
  getFullScheduleAction
};

const mapState = (state, props) => ({});

export default connect(mapState, mapActions)(NewReservationModal);
