import React from "react";
import ResponsiveHomepage from "../components/nav/ResponsiveHomepage";
import HomepageHeader from "../components/nav/HomepageHeader";
// import PropTypes from "prop-types";

const SignupPage = () => {
  return (
    <div className="sign-up__container">
      <ResponsiveHomepage />
      <HomepageHeader />
      <div className="sign-up__message">
        <p>L'application est en version Alpha.</p>
        <p>
          Si vous souhaitez participer à la phase Béta, veuillez vous inscrire
          en envoyant un email à : nruelle@helyios.com
        </p>
      </div>
    </div>
  );
};

export default SignupPage;
