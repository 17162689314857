import React from "react";
import { connect } from "react-redux";
import LogListItem from "./LogListItem";

// import PropTypes from 'prop-types'

class LogList extends React.Component {
  state = {
    page: 1
  };
  onLoadMore = async () => {
    this.setState(prevState => ({
      page: prevState.page + 1
    }));
  };
  render() {
    const { loggedWorkouts, loading } = this.props;
    if (loading) {
      return <div className="loader"></div>;
    } else {
      return (
        <div className="log-dashboard__container--list">
          {loggedWorkouts && loggedWorkouts.length > 0 ? (
            loggedWorkouts.map((e, i) => {
              if (i <= this.state.page * 4) {
                return <LogListItem key={e.workoutId} logEntry={e} />;
              } else {
                return null;
              }
            })
          ) : (
            <p className="no-logged-workouts">
              Pas d'entrainements enregistrés
            </p>
          )}
          {loggedWorkouts && loggedWorkouts.length > this.state.page * 4 + 1 && (
            <div className="log-dashboard__load-more">
              <button
                onClick={this.onLoadMore}
                className="log-dashboard__load-more-button"
              >
                Charger plus...
              </button>
            </div>
          )}
        </div>
      );
    }
  }
}

LogList.propTypes = {};

const mapState = (state, props) => ({
  loggedWorkouts: state.workoutLog.loggedWorkouts,
  loading: state.async.loading
});

const mapActions = {};

export default connect(mapState, mapActions)(LogList);
