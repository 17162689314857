import React from "react";
import CalendarDate from "../dashboard/CalendarDate";
import { connect } from "react-redux";
import { openModalAction } from "../../../redux/modals/modalsActions";
import LogDetails from "../modals/log/LogDetails";
import StarsDisplay from "./StarsDisplay";
// import PropTypes from "prop-types";
import { deleteLogWorkoutAction } from "../../../redux/log/logActions";

const LogListItem = ({ logEntry, openModalAction, deleteLogWorkoutAction }) => {
  let workoutDate;
  if (logEntry.date._seconds) {
    workoutDate = new Date(0);
    workoutDate.setUTCSeconds(logEntry.date._seconds);
  } else {
    workoutDate = new Date(logEntry.date);
  }
  const modal = {
    modalHeader: `Entrainement du ${workoutDate.toLocaleDateString("fr")}`,
    modalComponent: (
      <LogDetails logEntry={{ ...logEntry, date: workoutDate }} />
    ),
    modalType: "delete",
    confirmAction: () => deleteLogWorkoutAction(logEntry.workoutId)
  };
  return (
    <div className="log-dashboard__container--list-item">
      <CalendarDate date={workoutDate} />
      {logEntry.isRx ? (
        <i className="far fa-prescription rx-icon green"></i>
      ) : (
        <i className="far fa-prescription rx-icon grey"></i>
      )}

      <div className="log-dashboard__container--workout-type">
        {logEntry && logEntry.workoutType}
      </div>
      <div className="log-dashboard__container--movements">
        {logEntry &&
          logEntry.exercises.map((e, i) => <span key={i}>{e.movement} </span>)}
      </div>
      {/* workoutRating ==> Afficher nombre d'étoiles correspondantes (créer un composant) */}
      <StarsDisplay workoutRating={logEntry.workoutRating} />
      <button
        className="log-dashboard__container--list-item--button"
        onClick={() => openModalAction(modal)}
      >
        <i className="fal fa-arrow-circle-right"></i>
      </button>
    </div>
  );
};

LogListItem.propTypes = {};

const mapState = (state, props) => ({});

const mapActions = {
  openModalAction,
  deleteLogWorkoutAction
};

export default connect(mapState, mapActions)(LogListItem);
