import React from "react";
// import PropTypes from "prop-types";
import { connect } from "react-redux";
// import { openModalAction } from "../../../../redux/modals/modalsActions";
// import MembersForm from "./MembersForm";
import { dateUtil } from "../../../../utils/dateUtil";

const MembersDetailsModal = ({ member }) => {
  // console.log(member);
  const subscriptionDate = dateUtil(member.createdAt);
  const birthDate = dateUtil(member.birthDate);
  return (
    <div className="members-details-modal__container">
      <div className="members-details-modal__row">
        <div className="members-details-modal__row--left">
          <img src={member.imageUrl} alt={member.firstName} />
        </div>
        <div className="members-details-modal__row--right">
          <div className="members-details-modal__row2">
            <strong>Email : </strong>
            <span>{member.email}</span>
          </div>
          <div className="members-details-modal__row2">
            <strong>Téléphone : </strong>
            <span>
              {member.phoneNumber &&
                member.phoneNumber.match(/.{1,2}/g).join(" ")}
            </span>
          </div>
          <div className="members-details-modal__row2">
            <strong>Adresse : </strong>
            <span>
              {member.address && member.address}{" "}
              {member.postalCode && member.postalCode}{" "}
              {member.city && member.city}
            </span>
          </div>
          <div className="members-details-modal__row2">
            <strong>Inscrit le : </strong>
            <span>{subscriptionDate.toLocaleDateString("fr")}</span>
          </div>
          <div className="members-details-modal__row2">
            <strong>Date de naissance : </strong>
            <span>{birthDate.toLocaleDateString("fr")}</span>
          </div>
          {member.newUserPassword && member.newUserPassword !== "" && (
            <div className="members-details-modal__row2">
              <strong>Mot de passe à la création : </strong>
              <span>{member.newUserPassword}</span>
            </div>
          )}
        </div>
      </div>
      <div className="members-details-modal__row"></div>
      <div className="members-details-modal__row"></div>
    </div>
  );
};

MembersDetailsModal.propTypes = {};

const mapState = (state, props) => ({});

const mapActions = {};

export default connect(mapState, mapActions)(MembersDetailsModal);
