import { OPEN_RESPONSIVE_MENU, CLOSE_RESPONSIVE_MENU } from "./navConstants";
import { createReducer } from "../../utils/reducerUtil";

const initialState = {};

export const openRespMenu = state => ({
  ...state,
  open: true
});

export const closeRespMenu = state => ({
  ...state,
  open: false
});

export default createReducer(initialState, {
  [OPEN_RESPONSIVE_MENU]: openRespMenu,
  [CLOSE_RESPONSIVE_MENU]: closeRespMenu
});
