import { createReducer } from "../../utils/reducerUtil";
import {
  DELETE_SELF_FROM_RESERVATION,
  GET_FULL_SCHEDULE,
  GET_USER_RESERVATIONS,
  DELETE_USER_FROM_RESERVATION,
  ADD_USER_TO_RESERVATION,
  ADD_SELF_TO_RESERVATION,
  CREATE_NEW_RESERVATION_SELF,
  CREATE_NEW_RESERVATION
} from "./scheduleConstants";

const initialState = {};

export const getUserReservations = (state, payload) => {
  return {
    ...state,
    ...payload
  };
};

export const addUserToReservation = (state, payload) => {
  const updatedReservations = state.activeReservations.map(resa => {
    if (resa.reservationId === payload.reservationId) {
      return {
        ...resa,
        registeredUsers: [...resa.registeredUsers, payload.userId]
      };
    } else {
      return resa;
    }
  });

  return {
    ...state,
    activeReservations: [...updatedReservations]
  };
};

export const addSelfToReservation = (state, payload) => {
  const updatedReservation = state.activeReservations.find(resa => {
    if (resa.reservatioId === payload.reservationId) {
    }
    return resa.reservationId === payload.reservationId;
  });
  console.log(updatedReservation);
  return {
    ...state,
    userReservations: [...state.userReservations, updatedReservation]
  };
};

export const newReservation = (state, payload) => {
  const newReservation = {
    ...payload.reservation,
    registeredUsers: [payload.userId]
  };
  return {
    ...state,
    activeReservations: [...state.activeReservations, newReservation]
  };
};

export const newSelfReservation = (state, payload) => {
  return {
    ...state,
    userReservations: [...state.userReservations, payload]
  };
};

export const deleteUserFromReservation = (state, payload) => {
  const updatedReservations = state.activeReservations.map(resa => {
    if (resa.reservationId === payload.reservationId) {
      return {
        ...resa,
        registeredUsers: resa.registeredUsers.filter(
          user => user !== payload.userId
        )
      };
    } else {
      return resa;
    }
  });
  return {
    ...state,
    activeReservations: [...updatedReservations]
  };
};

export const removeSelfFromReservation = (state, payload) => {
  const newReservationArr = state.userReservations.filter(
    e => e.reservationId !== payload
  );
  return { ...state, userReservations: newReservationArr };
};

export const getFullSchedule = (state, payload) => {
  const workoutSchedule = payload.workoutSchedule;
  const activeReservations = payload.activeReservations;
  return {
    ...state,
    activeReservations,
    workoutSchedule
  };
};

export default createReducer(initialState, {
  [GET_USER_RESERVATIONS]: getUserReservations,
  [DELETE_SELF_FROM_RESERVATION]: removeSelfFromReservation,
  [GET_FULL_SCHEDULE]: getFullSchedule,
  [DELETE_USER_FROM_RESERVATION]: deleteUserFromReservation,
  [ADD_USER_TO_RESERVATION]: addUserToReservation,
  [ADD_SELF_TO_RESERVATION]: addSelfToReservation,
  [CREATE_NEW_RESERVATION_SELF]: newSelfReservation,
  [CREATE_NEW_RESERVATION]: newReservation
});
