import React from "react";
// import PropTypes from "prop-types";

const LessonPlan = props => {
  return (
    <div>
      <h3>Prochainement...</h3>
      <p>
        Une page sera dédiée aux coachs et owner pour pouvoir préparer le
        déroulement des WODs.
      </p>
    </div>
  );
};

LessonPlan.propTypes = {};

export default LessonPlan;
