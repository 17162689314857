import React from "react";
import { connect } from "react-redux";
// import PropTypes from "prop-types";
import { uploadUserImageAction } from "../../../../redux/user/userActions";

class UploadUserImageModal extends React.Component {
  onImageChange = e => {
    const image = e.target.files[0];
    const formData = new FormData();
    formData.append("image", image, image.name);
    this.props.uploadUserImageAction(formData);
  };
  render() {
    const { loading } = this.props;
    if (loading) {
      return (
        <div className="upload-image-modal">
          <div className="loader"></div>
        </div>
      );
    } else {
      return (
        <div className="upload-image-modal">
          <input
            type="file"
            name="profile-picture"
            onChange={this.onImageChange}
          />
          <p>
            Pour le meilleur rendu possible, nous vous recommandons d'utiliser
            une image carrée.
          </p>
        </div>
      );
    }
  }
}

UploadUserImageModal.propTypes = {};

const mapState = (state, props) => ({
  loading: state.async.loading
});

const mapActions = {
  uploadUserImageAction
};

export default connect(mapState, mapActions)(UploadUserImageModal);
