import React from "react";
// import { connect } from "react-redux";
import DashContainer from "../../components/DashContainer";
import MembersList from "../../components/members/MembersList";
// import { openModalAction } from "../../../redux/modals/modalsActions";

// import PropTypes from "prop-types";

class MembersDashboard extends React.Component {
  state = {
    pageNb: 1
  };
  render() {
    return (
      <React.Fragment>
        <DashContainer
          Component={MembersList}
          styleClassName="member-dashboard__main"
        />
      </React.Fragment>
    );
  }
}

export default MembersDashboard;
