import React from "react";
// import PropTypes from 'prop-types'
import { connect } from "react-redux";
import { openModalAction } from ".././../../redux/modals/modalsActions";
import ScheduleSetterTableModal from "../modals/schedule/ScheduleSetterTableModal";

const WorkoutSchedulerButton = ({
  openModalAction,
  workoutName,
  workoutType,
}) => {
  const modal = {
    modalHeader: `Planning de ${workoutName}`,
    modalComponent: <ScheduleSetterTableModal workoutType={workoutType} />,
    formAction: true,
    modalType: "confirm",
  };
  return (
    <button
      className="workout-scheduler__selection-button"
      onClick={() => openModalAction(modal)}
    >
      {workoutName}
    </button>
  );
};

WorkoutSchedulerButton.propTypes = {};

const mapState = (state, props) => ({});

const mapActions = {
  openModalAction,
};

export default connect(mapState, mapActions)(WorkoutSchedulerButton);
