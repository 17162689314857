import { createReducer } from "../../utils/reducerUtil";
import {
  GET_ALL_HEALTH_LOGS,
  GET_LAST_HEALTH_LOG,
  NEW_HEALTH_LOG
} from "./healthConstants";

const initialState = {};

export const getAllHealthLogs = (state, payload) => {
  return { ...state, ...payload };
};

export const getLastHealthLog = (state, payload) => {
  return { ...state, ...payload };
};

export const newHealthLog = (state, payload) => {
  const currentHealth = [{ ...payload }];
  return { ...state, currentHealth };
};

export default createReducer(initialState, {
  [GET_ALL_HEALTH_LOGS]: getAllHealthLogs,
  [GET_LAST_HEALTH_LOG]: getLastHealthLog,
  [NEW_HEALTH_LOG]: newHealthLog
});
