import React from "react";
// import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import { connect } from "react-redux";
import {
  createMemberAction,
  updateMemberAction
} from "../../../../redux/members/membersActions";
import { closeModalAction } from "../../../../redux/modals/modalsActions";

const MembersForm = ({
  createMemberAction,
  closeModalAction,
  member,
  updateMemberAction
}) => {
  let initialValues = {};
  if (member) {
    initialValues = {
      ...member,
      birthDate: new Date(member.birthDate._seconds * 1000)
        .toISOString()
        .substr(0, 10)
    };
  }

  return (
    <div className="new-box-message__container">
      <Formik
        initialValues={initialValues}
        onSubmit={values => {
          member
            ? updateMemberAction(values, member.userId)
            : createMemberAction(values);

          closeModalAction();
        }}
      >
        {props => (
          <Form id="modal-form">
            <div className="form-element">
              <label htmlFor="firstName">Prénom</label>
              <br />
              <Field name="firstName" />
            </div>
            <div className="form-element">
              <label htmlFor="lastName">Nom</label>
              <br />
              <Field name="lastName" />
            </div>
            <div className="form-element">
              <label htmlFor="phoneNumber">N° de Tel</label>
              <br />
              <Field name="phoneNumber" />
            </div>
            <div className="form-element">
              <label htmlFor="email">Email</label>
              <br />
              <Field name="email" />
            </div>
            <div className="form-element">
              <label htmlFor="address">Adresse</label>
              <br />
              <Field name="address" />
            </div>
            <div className="form-element">
              <label htmlFor="postalCode">Code Postal</label>
              <br />
              <Field name="postalCode" />
            </div>
            <div className="form-element">
              <label htmlFor="city">Ville</label>
              <br />
              <Field name="city" />
            </div>
            <div className="form-element">
              <label htmlFor="birthDate">Date de naissance</label>
              <br />
              <Field type="date" name="birthDate" />
            </div>
            {/* Membership plan */}
          </Form>
        )}
      </Formik>
    </div>
  );
};

MembersForm.propTypes = {};

const mapState = (state, props) => ({});

const mapActions = {
  createMemberAction,
  closeModalAction,
  updateMemberAction
};

export default connect(mapState, mapActions)(MembersForm);
