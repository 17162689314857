import React, { Component } from "react";
// import PropTypes from "prop-types";

class WodApp extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        {/* Timer */}
        <h3>Prochainement...</h3>
        <p>
          Une appli mobile sera mise à votre disposition pour simplifier le
          travail de vos coachs durant la séance d'entrainement.
        </p>
        {/* Whiteboard */}
      </div>
    );
  }
}

export default WodApp;
