import React, { Component } from "react";
import DashContainer from "../../components/DashContainer";
import BoxMessageContainer from "../../components/messages/BoxMessageContainer";
import TodayInfoContainer from "../../components/dashboard/TodayInfoContainer";
import MembersInfoContainer from "../../components/members/MembersInfoContainer";
import ScheduleDashboard from "../../components/dashboard/ScheduleDashboard";
import { connect } from "react-redux";

class HomeDashboard extends Component {
  async componentDidMount() {}
  static propTypes = {};

  render() {
    const { user } = this.props;
    return (
      <React.Fragment>
        <DashContainer
          Component={BoxMessageContainer}
          styleClassName="box-message__container"
        />
        {user && user.profileType === "athlete" ? (
          <DashContainer
            Component={TodayInfoContainer}
            styleClassName="today-info__container"
          />
        ) : (
          <DashContainer
            Component={MembersInfoContainer}
            styleClassName="members-info__container"
          />
        )}

        <DashContainer
          Component={ScheduleDashboard}
          styleClassName="schedule-dashboard__container"
        />
      </React.Fragment>
    );
  }
}

const mapState = (state, props) => ({
  user: state.user
});

const mapActions = {};

export default connect(mapState, mapActions)(HomeDashboard);
