import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ScrollTop from "./config/scrollTop";
import Homepage from "./layout/pages/Homepage";
import Dashboard from "./layout/pages/dashboard/Dashboard";
import "./style.scss";
import HamburgerMenu from "./layout/components/nav/HamburgerMenu";
import ResponsiveMenu from "./layout/components/nav/ResponsiveMenu";
import LoginPage from "./layout/pages/LoginPage";
import SignupPage from "./layout/pages/SignupPage";
import AuthRoute from "./utils/AuthRoute";
import { connect } from "react-redux";
import { checkUserAuth } from "./redux/auth/authActions";
import Modal from "./layout/components/modals/Modal";
import axios from "axios";
import { openModalAction } from "./redux/modals/modalsActions";
import BoxMessageDetailsModal from "./layout/components/modals/messages/BoxMessageDetailsModal";

axios.defaults.baseURL =
  "https://europe-west1-helyios-app.cloudfunctions.net/api";

const App = (props) => {
  const { isFetching, isAuthenticated } = props.auth;
  const { modal } = props;

  useEffect(() => {
    const checkAuth = async () => {
      await props.checkUserAuth();
    };
    checkAuth();
    // props.openModalAction({
    //   modalComponent: (
    //     <BoxMessageDetailsModal
    //       message={{
    //         body:
    //           "En raison du Covid-19 le lancement de la phase béta de l'application est reportée au 1er Octobre 2020. Et la commercialisation au 1er Janvier 2021.",
    //       }}
    //     />
    //   ),
    //   modalHeader: "COVID-19",
    // });
  }, []);

  useEffect(() => {
    if (modal.open) {
      window.document.body.style.overflowY = "hidden";
    } else {
      window.document.body.style.overflowY = "auto";
    }
  }, [modal]);

  return (
    <div className={modal && modal.open ? "modal-open" : ""}>
      <ScrollTop>
        {modal && modal.open && <Modal />}
        <Switch>
          <Route exact path="/" component={Homepage} />
          {isFetching === false && isAuthenticated === true ? (
            <Route
              exact
              path="/login"
              render={(props) => <Redirect to="/dashboard" />}
            />
          ) : (
            <Route exact path="/login" component={LoginPage} />
          )}

          <Route exact path="/signup" component={SignupPage} />
          {isFetching === false && (
            <AuthRoute path="/(.+)" component={AuthenticatedComponent} />
          )}
        </Switch>
      </ScrollTop>
    </div>
  );
};

const actions = {
  checkUserAuth,
  openModalAction,
};

const mapState = (state) => ({
  auth: state.auth,
  modal: state.modal,
});

export default connect(mapState, actions)(App);

const AuthenticatedComponent = () => (
  <React.Fragment>
    <HamburgerMenu />
    <ResponsiveMenu />
    <Dashboard />
  </React.Fragment>
);
