import React from "react";
// import PropTypes from "prop-types";
import Profile from "./Profile";
import SideMenu from "./SideMenu";

const SidePanel = ({ user, signOut }) => (
  <React.Fragment>
    <img
      src="https://firebasestorage.googleapis.com/v0/b/helyios-app.appspot.com/o/LogoWY.png?alt=media&token=90913127-e4e5-4bf0-9fef-5875583ae00a"
      className="brand-logo-responsive"
      alt="Helyios Logo"
    />
    <div className="side-panel">
      <div className="brand">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/helyios-app.appspot.com/o/LogoWY.png?alt=media&token=90913127-e4e5-4bf0-9fef-5875583ae00ag"
          className="brand-logo"
          alt="Helyios Logo"
        />
        <h1 className="brand-title">Helyios</h1>
      </div>
      <Profile user={user} signOut={signOut} />
      <div className="side-panel-divider" />
      <SideMenu user={user} />
    </div>
  </React.Fragment>
);

export default SidePanel;
