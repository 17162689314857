import React from "react";
// import PropTypes from 'prop-types'

const LogHeader = ({ formOpen, onButtonClick }) => {
  return (
    <div>
      {formOpen ? (
        <div
          className="log-container__header--button left"
          onClick={() => onButtonClick()}
        >
          <i className="fal fa-clipboard-prescription"></i> Log
        </div>
      ) : (
        <div
          className="log-container__header--button right"
          onClick={() => onButtonClick()}
        >
          <i className="fal fa-plus-circle"></i> Nouvelle entrée
        </div>
      )}

      <h1 className={formOpen ? "header-right" : "header-left"}>
        {formOpen ? "Nouvelle entrée" : "Cahier d'entrainement"}
      </h1>
    </div>
  );
};

LogHeader.propTypes = {};

export default LogHeader;
