import React from "react";
import { Formik, Form, Field } from "formik";
import { connect } from "react-redux";
import { newHealthLogAction } from "../../../redux/health/healthActions";
// import PropTypes from "prop-types";

const LogHealth = ({ health, newHealthLogAction }) => {
  let initialValues;
  if (health.currentHealth && health.currentHealth[0]) {
    initialValues = {
      weight: health.currentHealth[0].weight,
      restingHr: health.currentHealth[0].restingHr,
      pressure: health.currentHealth[0].pressure,
      vo2max: health.currentHealth[0].vo2max
    };
  } else {
    initialValues = {
      weight: "",
      restingHr: "",
      pressure: "",
      vo2max: ""
    };
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={values => {
          if (values !== initialValues) {
            newHealthLogAction(values);
          }
        }}
      >
        <Form id="health-form">
          <div className="log-dashboard__log-health--form">
            <div className="log-dashboard__log-health--form-element">
              <label htmlFor="weight">Poids</label>
              <br />
              <Field
                type="number"
                name="weight"
                placeholder="Poids"
                step="any"
              />
            </div>

            <div className="log-dashboard__log-health--form-element">
              <label htmlFor="restingHr">F.C. au repos</label>
              <br />
              <Field
                type="number"
                name="restingHr"
                placeholder="F.C. au repos"
                step="any"
              />
            </div>
            <div className="log-dashboard__log-health--form-element">
              <label htmlFor="pressure">Pression arterielle</label>
              <br />
              <Field
                type="number"
                name="pressure"
                placeholder="Pression artérielle"
                step="any"
              />
            </div>

            <div className="log-dashboard__log-health--form-element">
              <label htmlFor="vo2max">VO2 Max</label>
              <br />
              <Field
                type="number"
                name="vo2max"
                placeholder="VO2 Max"
                step="any"
              />
            </div>
          </div>

          <button className="log-dashboard__log-health--button" type="submit">
            CONFIRMER
          </button>
        </Form>
      </Formik>
    </div>
  );
};

LogHealth.propTypes = {};

const mapState = (state, props) => ({
  health: state.health
});

const mapActions = {
  newHealthLogAction
};

export default connect(mapState, mapActions)(LogHealth);
