import React from "react";
import MembersListItem from "./MembersListItem";
// import PropTypes from "prop-types";
import { connect } from "react-redux";
import { openModalAction } from "../../../redux/modals/modalsActions";
import MembersForm from "../modals/members/MembersForm";
import { sortLastNameUtil } from "../../../utils/orderUtil";

class MembersList extends React.Component {
  render() {
    const { members, loading, openModalAction } = this.props;
    const modal = {
      modalHeader: "Nouveau membre...",
      modalComponent: <MembersForm />,
      modalType: "confirm",
      formAction: true
    };
    let membersList;
    if (members) {
      membersList = sortLastNameUtil(members);
    }

    return (
      <div className="members-list__container">
        <div className="members-list__container--header">
          <div>
            <span>PHOTO</span>
          </div>
          <div>NOM</div>
          <div>PRÉNOM</div>
          <div>EMAIL</div>
          <button
            className="members-list__container--add"
            onClick={() => openModalAction(modal)}
          >
            <i className="fas fa-user-plus"></i>
          </button>
        </div>
        {loading && <div className="loader"></div>}
        {!loading &&
          membersList &&
          membersList.map((e, i) => {
            return (
              <MembersListItem
                key={e.userId}
                member={e}
                openModalAction={openModalAction}
              />
            );
          })}
      </div>
    );
  }
}

MembersList.propTypes = {};

const mapState = (state, props) => ({
  loading: state.async.loading,
  members: state.members.boxMembers
});

const mapActions = {
  openModalAction
};

export default connect(mapState, mapActions)(MembersList);
