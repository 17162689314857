import React from "react";
// import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { closeRespMenu } from "../../../redux/nav/navActions";

const HomepageNav = ({ closeRespMenu }) => {
  return (
    <nav>
      <NavLink
        to="/"
        onClick={() => closeRespMenu()}
        exact
        className="homepage__button"
        activeClassName="active2"
      >
        ACCUEIL
      </NavLink>

      <NavLink
        onClick={() => closeRespMenu()}
        className="homepage__button"
        to="/login"
        activeClassName="active2"
      >
        CONNEXION
      </NavLink>

      <NavLink
        onClick={() => closeRespMenu()}
        className="homepage__button--signup"
        to="/signup"
        activeClassName="active2"
      >
        S'INSCRIRE
      </NavLink>
    </nav>
  );
};

const mapState = (state, props) => ({});

const mapActions = {
  closeRespMenu
};

HomepageNav.propTypes = {};

export default connect(mapState, mapActions)(HomepageNav);
