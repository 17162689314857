import React from "react";
// import PropTypes from "prop-types";

const DashContainer = ({ Component, styleClassName, ...rest }) => {
  return (
    <div className={`segment-dash ${styleClassName}`}>
      <Component {...rest} />
    </div>
  );
};

DashContainer.propTypes = {};

export default DashContainer;
