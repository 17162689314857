import React from "react";
// import PropTypes from 'prop-types'

const ScheduleTableHeaderControl = ({ date, changeDate, endDate }) => {
  let responsive = false;
  if (window.innerWidth <= 575) {
    responsive = true;
  }
  return (
    <div className="schedule-dashboard__controls">
      <ScheduleTableControlBtn
        iconClass="fal fa-arrow-circle-left"
        supClass="blue-control-icon"
        changeDate={changeDate}
      />
      <h4>
        {date.toLocaleDateString("fr-FR")}{" "}
        {endDate && !responsive && (
          <React.Fragment>{`- ${endDate.toLocaleDateString(
            "fr-FR"
          )}`}</React.Fragment>
        )}
      </h4>
      <ScheduleTableControlBtn
        iconClass="fal fa-arrow-circle-right"
        supClass="blue-control-icon"
        changeDate={changeDate}
      />
    </div>
  );
};

export const ScheduleTableControlBtn = ({
  iconClass,
  supClass,
  changeDate
}) => {
  const iconClassArr = iconClass.split("-");
  let changeDateArg;
  if (iconClassArr[iconClassArr.length - 1] === "right") {
    changeDateArg = "add";
  } else if (iconClassArr[iconClassArr.length - 1] === "left") {
    changeDateArg = "remove";
  }
  return (
    <button onClick={() => changeDate(changeDateArg)}>
      <i className={`${supClass} ${iconClass} `}></i>
    </button>
  );
};

// ScheduleTableHeaderControl.propTypes = {

// }

export default ScheduleTableHeaderControl;
