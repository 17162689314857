import {
  GET_LOG_WORKOUT,
  GET_ALL_WORKOUTS,
  UPDATE_LOG_WORKOUT,
  CREATE_LOG_WORKOUT,
  DELETE_LOG_WORKOUT
} from "./logConstants";
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from "../async/asyncActions";
import axios from "axios";
import { toastr } from "react-redux-toastr";

export const getAllWorkoutsAction = () => async dispatch => {
  try {
    dispatch(asyncActionStart());
    const res = await axios.get(`/workouts/`);
    dispatch({ type: GET_ALL_WORKOUTS, payload: res.data });
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log({ ...error });
    dispatch(asyncActionError());
  }
};

export const getFirstTenWorkoutsAction = () => async dispatch => {
  try {
    dispatch(asyncActionStart());
    const res = await axios.get(`/first-workouts/`);
    dispatch({ type: GET_ALL_WORKOUTS, payload: res.data });
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log({ ...error });
    dispatch(asyncActionError());
  }
};

export const getLogWorkoutAction = workoutId => async dispatch => {
  try {
    const res = await axios.get(`/workout/${workoutId}`);
    dispatch({ type: GET_LOG_WORKOUT, payload: res.data });
  } catch (error) {
    console.log({ ...error });
  }
};

export const updateLogWorkoutAction = (
  workout,
  workoutId
) => async dispatch => {
  try {
    await axios.post(`/workout/${workoutId}`, workout);
    toastr.success("Entrée de log modifiée");
    dispatch({ type: UPDATE_LOG_WORKOUT, payload: { ...workout, workoutId } });
  } catch (error) {
    console.log({ ...error });
  }
};

export const createLogWorkoutAction = (
  workout,
  closeForm
) => async dispatch => {
  console.log({ workout });
  try {
    const res = await axios.post("/workout", workout);
    const workoutId = res.id;
    toastr.success("Entrée de log crée");
    dispatch({
      type: CREATE_LOG_WORKOUT,
      payload: {
        ...workout,
        workoutId
      }
    });
    closeForm();
  } catch (error) {
    console.log({ ...error });
  }
};

export const deleteLogWorkoutAction = workoutId => async dispatch => {
  try {
    await axios.delete(`/workout/${workoutId}`);
    toastr.success("Entrée de log supprimée");
    dispatch({ type: DELETE_LOG_WORKOUT, payload: workoutId });
  } catch (error) {
    console.log({ ...error });
  }
};
