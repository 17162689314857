import React from "react";
// import PropTypes from "prop-types";
import { connect } from "react-redux";
import HomepageNav from "./HomepageNav";

const ResponsiveHomepage = ({ open }) => {
  if (open) {
    return (
      <div className="responsive-menu">
        <HomepageNav />
      </div>
    );
  } else return null;
};

ResponsiveHomepage.propTypes = {};

const mapState = (state, props) => ({
  open: state.responsive.open
});

export default connect(mapState)(ResponsiveHomepage);
