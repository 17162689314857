import {
  GET_ALL_HEALTH_LOGS,
  GET_LAST_HEALTH_LOG,
  NEW_HEALTH_LOG
} from "./healthConstants";
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from "../async/asyncActions";
import axios from "axios";
import { toastr } from "react-redux-toastr";

export const getLastHealthLogAction = () => async dispatch => {
  try {
    const doc = await axios.get("/user/current-health");
    dispatch({ type: GET_LAST_HEALTH_LOG, payload: doc.data });
  } catch (error) {
    console.log(error.message);
    toastr.error("Échec de la récupération des données de santé");
  }
};

export const newHealthLogAction = healthEntry => async dispatch => {
  try {
    await axios.post("/user/health", healthEntry);
    dispatch({ type: NEW_HEALTH_LOG, payload: { healthEntry } });
    toastr.success("Données de santé ajoutées");
  } catch (error) {
    toastr.error(
      "Échec de l'ajout des données de santé",
      "Veuillez réessayer. Si le problème persiste, contactez un administrateur"
    );
  }
};
