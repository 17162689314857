import React from "react";
// import PropTypes from 'prop-types'
import { connect } from "react-redux";
import { Formik, Form, Field } from "formik";
import { newBoxMessagesAction } from "../../../../redux/messages/messagesActions";
import { closeModalAction } from "../../../../redux/modals/modalsActions";

const NewBoxMessageModal = ({ newBoxMessagesAction, closeModalAction }) => {
  return (
    <div className="new-box-message__container">
      <Formik
        initialValues={{
          title: "",
          body: "",
          link: "",
          endDisplayDate: new Date()
        }}
        onSubmit={values => {
          newBoxMessagesAction(values);
          closeModalAction();
        }}
      >
        {props => (
          <Form id="modal-form">
            <div>
              <label htmlFor="title">Titre du message</label>
              <br />
              <Field name="title" placeholder="Titre" />
            </div>
            <div>
              <label htmlFor="body">Contenu du message</label>
              <br />
              <Field as="textarea" name="body" placeholder="Message..." />
            </div>

            <div>
              <label htmlFor="link">Lien à intégrer</label>
              <br />
              <Field name="link" placeholder="Lien" />
            </div>

            <div>
              <label htmlFor="endDisplayDate">Date de fin du message</label>
              <br />
              <Field type="date" name="endDisplayDate" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

NewBoxMessageModal.propTypes = {};

const mapState = (state, props) => ({});

const mapActions = {
  newBoxMessagesAction,
  closeModalAction
};

export default connect(mapState, mapActions)(NewBoxMessageModal);
