import React from "react";
import MenuItem from "./MenuItem";
import iconsSettings from "../../../utils/iconsUtil";

class SideMenu extends React.Component {
  state = {
    icons: []
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps && nextProps.user.profileType === "box") {
      return { icons: iconsSettings[1] };
    } else if (nextProps && nextProps.user.profileType === "athlete") {
      return { icons: iconsSettings[0] };
    } else {
      return null;
    }
  }

  render() {
    const { icons } = this.state;
    return (
      <nav className="side-menu">
        {icons &&
          icons.map(icon => (
            <MenuItem
              key={icon.name}
              iconType={icon.type}
              name={icon.name}
              path={icon.path}
              exact={icon.exact}
            />
          ))}
      </nav>
    );
  }
}

export default SideMenu;
