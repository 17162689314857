import React from "react";
import { monthConverter } from "../../../utils/monthConverter";

const CalendarDate = ({ date }) => {
  const today = date ? new Date(date) : new Date();
  let todayString = date;
  if (typeof date !== "string") {
    todayString = today.toLocaleDateString("fr");
  }
  todayString = todayString.substring(0, 10);
  const day = todayString.split("/")[0];
  const month = monthConverter(todayString.split("/")[1]);
  const year = todayString.split("/")[2];
  return (
    <div className="calendar-date__container">
      <div className="calendar-date__header">{year}</div>
      <div className="calendar-date__body">
        <div className="calendar-date__body__day">{day}</div>
        <div className="calendar-date__body__month">{month}</div>
      </div>
    </div>
  );
};

export default CalendarDate;
