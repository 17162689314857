import React from "react";
import ReservationMembersItem from "../../members/ReservationMembersItem";
import { connect } from "react-redux";
import {
  addUserToReservationAction,
  newReservationAction
} from "../../../../redux/schedule/scheduleActions";
import { closeModalAction } from "../../../../redux/modals/modalsActions";
// import PropTypes from "prop-types";

const AddMembersToReservationModal = ({
  members,
  resa,
  addUserToReservationAction,
  newReservationAction,
  closeModalAction,
  activeReservations
}) => {
  let activeReservation;
  if (resa && resa.reservationId) {
    activeReservation = activeReservations.find(
      e => e.reservationId === resa.reservationId
    );
  }
  return (
    <div>
      {members &&
        members.map(member => {
          let registered;
          if (activeReservation) {
            registered = activeReservation.registeredUsers.find(
              user => user === member.userId
            );
          }

          return (
            <ReservationMembersItem
              key={member.userId}
              member={member}
              action={registered ? "check" : "add"}
              click={async () => {
                if (resa.reservationId) {
                  addUserToReservationAction(resa.reservationId, member.userId);
                } else {
                  await newReservationAction(resa, member.userId);
                  closeModalAction();
                }
              }}
              resa={resa}
            />
          );
        })}
    </div>
  );
};

AddMembersToReservationModal.propTypes = {};

const mapState = (state, props) => ({
  activeReservations: state.schedule.activeReservations
});

const mapActions = {
  addUserToReservationAction,
  newReservationAction,
  closeModalAction
};

export default connect(mapState, mapActions)(AddMembersToReservationModal);
