import React from "react";
import { connect } from "react-redux";
import DashContainer from "../../components/DashContainer";
import LogMain from "../../components/log/LogMain";
import LogWeek from "../../components/log/LogWeek";
import LogHealth from "../../components/log/LogHealth";
// import LogDetails from "../../components/modals/log/LogDetails";
// import LogForm from "../../components/modals/log/LogForm";
import { openModalAction } from "../../../redux/modals/modalsActions";
// import { getAllWorkoutsAction } from "../../../redux/log/logActions";
// import PropTypes from "prop-types";

class LogDashboard extends React.Component {
  state = {
    pageNb: 1
  }; // TODO : Add page number

  render() {
    // const { openModalAction } = this.props;
    return (
      <React.Fragment>
        <DashContainer
          Component={LogWeek}
          styleClassName="log-dashboard__log-week"
        />
        <DashContainer
          Component={LogHealth}
          styleClassName="log-dashboard__log-health"
        />
        <DashContainer
          Component={LogMain}
          styleClassName="log-dashboard__container"
        />
      </React.Fragment>
    );
  }
}

const mapState = (state, props) => ({});

const mapActions = {
  openModalAction
};

export default connect(mapState, mapActions)(LogDashboard);
