import React from "react";
import PropTypes from "prop-types";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { closeRespMenu } from "../../../redux/nav/navActions";
import UpdateBlocker from "../../../config/updateBlocker";

const MenuItem = ({ path, iconType, name, closeMenu, exact, responsive }) => (
  <UpdateBlocker>
    <NavLink
      to={`${path}`}
      exact={exact}
      className="nav-link"
      activeClassName="active"
      onClick={responsive.open ? closeMenu : null}
    >
      <i className={`fal ${iconType}`} title={name} />
      <span>{name}</span>
    </NavLink>
  </UpdateBlocker>
);

MenuItem.propTypes = {
  iconType: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  closeMenu: PropTypes.func.isRequired,
  exact: PropTypes.bool.isRequired
};

const actions = {
  closeMenu: closeRespMenu
};

const mapState = state => ({
  responsive: state.responsive
});

export default withRouter(connect(mapState, actions)(MenuItem));
