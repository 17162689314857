import React from "react";
import PropTypes from "prop-types";

export default class UpdateBlocker extends React.PureComponent {
  render() {
    const { children } = this.props;
    return children;
  }
}

UpdateBlocker.propTypes = {
  children: PropTypes.element.isRequired
};
