import React, { Component } from "react";
import StarsDisplay from "../../log/StarsDisplay";
// import PropTypes from "prop-types";

// TODO : Fix starDisplay so when no stars are selected, it displays 5 grey stars

export class LogDetails extends Component {
  static propTypes = {};
  // On Edit Click ==> Modal (LogForm) with populated data
  render() {
    const { logEntry } = this.props;
    let minutes, seconds;
    if (Number(logEntry.minutesToComp) / 10 < 1) {
      minutes = "0" + String(logEntry.minutesToComp);
    } else {
      minutes = String(logEntry.minutesToComp);
    }
    if (
      Number(logEntry.secondsToComp) / 10 < 1 ||
      Number(logEntry.secondsToComp) === 0
    ) {
      seconds = "0" + String(logEntry.secondsToComp);
    } else {
      seconds = String(logEntry.secondsToComp);
    }
    const timeToComp = `${minutes}:${seconds}`;

    return (
      <div className="log-details-modal__container">
        <div className="log-details-modal__line">
          {`Type d'entrainement : ${logEntry.workoutType}`}
        </div>
        <div className="log-details-modal__line">
          {logEntry.nbRounds && `Nombre de rounds : ${logEntry.nbRounds}`}
        </div>
        <div className="log-details-modal__line">
          {`RX : ${logEntry.isRx ? "Oui" : "Non"}`}
        </div>
        <div className="log-details-modal__exercises">
          <span>Exercices:</span>
          {logEntry.exercises.map((e, i) => (
            <div key={i}>
              {`${e.reps} ${e.movement} `}
              {e.weight && `@ ${e.weight} kg`}
            </div>
          ))}
        </div>
        <div className="log-details-modal__line">
          {logEntry.minutesToComp && `Temps de complétion : ${timeToComp}`}
        </div>
        <div className="log-details-modal__line">
          <span>Note :</span>
          {logEntry.workoutRating >= 0 && (
            <StarsDisplay workoutRating={logEntry.workoutRating} />
          )}
        </div>
        <div className="log-details-modal__line">
          <span>Commentaires :</span>
          {logEntry.comments && <p>{logEntry.comments}</p>}
        </div>
      </div>
    );
  }
}

export default LogDetails;
