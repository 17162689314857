import React from "react";
// import PropTypes from 'prop-types'
import { connect } from "react-redux";

const MembersInfoContainer = ({ members, loading }) => {
  if (loading && !members) {
    return <div className="loader"></div>;
  }
  return (
    <div className="member-info__container--body">
      <span>{members && `${members.length}`}</span>
      <p>membres</p>
    </div>
  );
};

MembersInfoContainer.propTypes = {};

const mapState = (state, props) => ({
  members: state.members.boxMembers,
  loading: state.async.loading
});

const mapActions = {};

export default connect(mapState, mapActions)(MembersInfoContainer);
