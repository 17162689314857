export const SET_DEFAULT_SCHEDULE = "SET_DEFAULT_SCHEDULE";
export const GET_DEFAULT_SCHEDULE = "GET_DEFAULT_SCHEDULE";
export const GET_WORKOUT_SCHEDULE = "GET_WORKOUT_SCHEDULE";
export const GET_FULL_SCHEDULE = "GET_FULL_SCHEDULE";
export const GET_RESERVATION_DETAILS = "GET_RESERVATION_DETAILS";
export const ADD_USER_TO_RESERVATION = "ADD_USER_TO_RESERVATION";
export const ADD_SELF_TO_RESERVATION = "ADD_SELF_TO_RESERVATION";
export const DELETE_USER_FROM_RESERVATION = "DELETE_USER_FROM_RESERVATION";
export const GET_USER_RESERVATIONS = "GET_USER_RESERVATIONS";
export const DELETE_SELF_FROM_RESERVATION = "DELETE_SELF_FROM_RESERVATION";
export const CREATE_NEW_RESERVATION = "CREATE_NEW_RESERVATION";
export const CREATE_NEW_RESERVATION_SELF = "CREATE_NEW_RESERVATION_SELF";
export const CANCEL_NEW_RESERVATION = "CANCEL_NEW_RESERVATION";
export const CANCEL_EXISTING_RESERVATION = "CANCEL_EXISTING_RESERVATION";
export const UNCANCEL_RESERVATION = "UNCANCEL_RESERVATION";
