import React from "react";
import MembersDetailsModal from "../modals/members/MembersDetailsModal";
import MembersForm from "../modals/members/MembersForm";
// import PropTypes from "prop-types";

const MembersListItem = ({ member, openModalAction }) => {
  const modal2 = {
    modalHeader: `${member.lastName.toUpperCase()} ${member.firstName}`,
    modalComponent: <MembersForm member={member} />,
    modalType: "confirm",
    formAction: true,
    confirmAction: null
  };
  const modal = {
    modalHeader: `${member.lastName.toUpperCase()} ${member.firstName}`,
    modalComponent: <MembersDetailsModal member={member} />,
    modalType: "edit",
    formAction: false,
    confirmAction: () => openModalAction(modal2)
  };
  return (
    <div className="members-list__item">
      <div className="members-list__item--photo">
        <img src={member.imageUrl} alt={`${member.lastName}`} />
      </div>

      <div className="members-list__item--last-name">
        {member.lastName.toUpperCase()}
      </div>
      <div className="members-list__item--first-name">{member.firstName}</div>
      <div className="members-list__item--email">{member.email}</div>

      <button
        className="members-list__item--arrow-icon"
        onClick={() => openModalAction(modal)}
      >
        <i className="fal fa-arrow-circle-right"></i>
      </button>
    </div>
  );
};

MembersListItem.propTypes = {};

export default MembersListItem;
