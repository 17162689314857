import React from "react";
import { connect } from "react-redux";
import BoxMessageItem from "./BoxMessageItem";
import NewBoxMessageModal from "../modals/messages/NewBoxMessageModal";
import { openModalAction } from "../../../redux/modals/modalsActions";

const BoxMessageContainer = ({ messages, user, openModalAction, loading }) => {
  const { boxMessages } = messages;
  const modal = {
    modalHeader: "Nouveau message...",
    modalComponent: <NewBoxMessageModal />,
    formAction: true,
    modalType: "confirm"
  };
  if (loading && !boxMessages) {
    return <div className="loader"></div>;
  } else {
    return (
      <div>
        <h3>
          Messages de ma box :{" "}
          {user.profileType && user.profileType === "box" && (
            <button
              className="box-message__new-btn"
              onClick={() => openModalAction(modal)}
            >
              <i className="fal fa-edit"></i>
            </button>
          )}
        </h3>
        {boxMessages && boxMessages.length === 0 ? (
          <p>Pas de messages actuellement</p>
        ) : (
          <div>
            {boxMessages &&
              boxMessages.map((e, i) => {
                if (i < boxMessages.length - 1) {
                  return (
                    <BoxMessageItem
                      message={e}
                      key={e.messageId}
                      date={new Date(
                        e.createdAt._seconds * 1000
                      ).toLocaleDateString("fr")}
                    />
                  );
                } else {
                  return (
                    <BoxMessageItem
                      message={e}
                      key={e.messageId}
                      last
                      date={new Date(
                        e.createdAt._seconds * 1000
                      ).toLocaleDateString("fr")}
                    />
                  );
                }
              })}
          </div>
        )}
      </div>
    );
  }
};

const mapState = state => ({
  auth: state.auth,
  user: state.user,
  messages: state.messages,
  loading: state.async.loading
});

const mapActions = {
  openModalAction
};

export default connect(mapState, mapActions)(BoxMessageContainer);
