import React from "react";
// import PropTypes from "prop-types";

const StarsDisplay = ({ workoutRating }) => {
  const starNb = Number(workoutRating);
  let starsArr = [];
  for (let i = 0; i < 5; i++) {
    if (i < starNb) {
      starsArr.push("yellow");
    } else {
      starsArr.push("grey");
    }
  }
  return (
    <div className="log-dashboard__container--stars">
      {starsArr.map((e, i) => (
        <i key={i} className={`fas fa-star ${e}`}></i>
      ))}
    </div>
  );
};

StarsDisplay.propTypes = {};

export default StarsDisplay;
