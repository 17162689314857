import { createReducer } from "../../utils/reducerUtil";
import {
  GET_BOX_MESSAGES,
  DELETE_BOX_MESSAGE,
  NEW_BOX_MESSAGE,
  UPDATE_BOX_MESSAGE
} from "./messagesConstants";

const initialState = {};

export const getBoxMessages = (state, payload) => {
  return { ...state, ...payload };
};
export const deleteBoxMessage = (state, payload) => {
  const messages = state.boxMessages.filter(e => e.messageId !== payload);
  return { ...state, boxMessages: messages };
};
export const newBoxMessage = (state, payload) => {
  const newMessage = {
    ...payload
  };
  return { ...state, boxMessages: [...state.boxMessages, newMessage] };
};
export const updateBoxMessage = (state, payload) => {
  return { ...state, ...payload };
};

export default createReducer(initialState, {
  [GET_BOX_MESSAGES]: getBoxMessages,
  [DELETE_BOX_MESSAGE]: deleteBoxMessage,
  [UPDATE_BOX_MESSAGE]: updateBoxMessage,
  [NEW_BOX_MESSAGE]: newBoxMessage
});
