import React from "react";
// import PropTypes from "prop-types";

const BoxMessageDetailsModal = ({ message }) => {
  return (
    <div>
      {message.body && message.body}

      {message.link && (
        <div>
          <a target="_blank" rel="noopener noreferrer" href={message.link}>
            {message.link}
          </a>
        </div>
      )}
    </div>
  );
};

BoxMessageDetailsModal.propTypes = {};

export default BoxMessageDetailsModal;
