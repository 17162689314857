// 1: Athlete, 2: Box, 3: Marketplace Seller

const icons = [
  [
    {
      name: "Accueil",
      type: "fa-home",
      path: "/dashboard",
      exact: true
    },
    {
      name: "Planning",
      type: "fa-calendar-alt",
      path: "/planning",
      exact: false
    },
    {
      name: "Log",
      type: "fa-clipboard-prescription", // 'fa-clipboard'
      path: "/log",
      exact: false
    },
    {
      name: "Marketplace",
      type: "fa-shopping-cart",
      path: "/marketplace",
      exact: false
    },
    {
      name: "Compétitions",
      type: "fa-trophy",
      path: "/competition",
      exact: false
    }
    // {
    //   name: "Paiements",
    //   type: "fa-coins",
    //   path: "/paiements",
    //   exact: false
    // },
    // {
    //   name: "Paramètres",
    //   type: "fa-cog",
    //   path: "/settings",
    //   exact: false
    // }
  ],
  [
    {
      name: "Accueil",
      type: "fa-home",
      path: "/dashboard",
      exact: true
    },
    {
      name: "Planning",
      type: "fa-calendar-alt",
      path: "/schedule",
      exact: false
    },
    {
      name: "Membres",
      type: "fa-users",
      path: "/members",
      exact: false
    },
    {
      name: "Wod Builder",
      type: "fa-dumbbell",
      path: "/wod-builder",
      exact: false
    },
    {
      name: "Lesson Plan",
      type: "fa-clipboard-list",
      path: "/lesson-plan",
      exact: false
    },
    {
      name: "WOD App",
      type: "fa-clock",
      path: "/wod-app",
      exact: false
    },
    {
      name: "Paiements",
      type: "fa-coins",
      path: "/paiements",
      exact: false
    }
    // {
    //   name: "Paramètres",
    //   type: "fa-cog",
    //   path: "/settings",
    //   exact: false
    // }
  ],
  [
    {
      name: "Accueil",
      type: "fa-home",
      path: "/",
      exact: true
    },
    {
      name: "Planning",
      type: "fa-calendar-alt",
      path: "/planning",
      exact: false
    },
    {
      name: "Log",
      type: "fa-clipboard-prescription",
      path: "/log",
      exact: false
    },
    {
      name: "Marketplace",
      type: "fa-shopping-cart",
      path: "/marketplace",
      exact: false
    },
    {
      name: "Compétitions",
      type: "fa-trophy",
      path: "/competition",
      exact: false
    },
    {
      name: "Paiements",
      type: "fa-coins",
      path: "/paiements",
      exact: false
    }
    // {
    //   name: "Paramètres",
    //   type: "fa-cog",
    //   path: "/settings",
    //   exact: false
    // }
  ]
];

export default icons;
