import { createReducer } from "../../utils/reducerUtil";
import {
  GET_USER_DETAILS,
  UPLOAD_USER_IMAGE,
  UPDATE_USER_DETAILS,
  REMOVE_USER_DETAILS
} from "./userConstants";

const initialState = {};

export const getUser = (state, payload) => {
  return { ...state, ...payload };
};
export const uploadUserImage = (state, payload) => {
  return { ...state, ...payload };
};
export const updateUserDetails = (state, payload) => {
  return { ...state, ...payload.userData };
};

export const removeUserDetails = (state, payload) => {
  return {};
};

export default createReducer(initialState, {
  [GET_USER_DETAILS]: getUser,
  [UPLOAD_USER_IMAGE]: uploadUserImage,
  [UPDATE_USER_DETAILS]: updateUserDetails,
  [REMOVE_USER_DETAILS]: removeUserDetails
});
