import { createReducer } from "../../utils/reducerUtil";
import {
  GET_ALL_WORKOUTS,
  GET_LOG_WORKOUT,
  UPDATE_LOG_WORKOUT,
  CREATE_LOG_WORKOUT,
  DELETE_LOG_WORKOUT
} from "./logConstants";

const initialState = {};

export const getAllWorkouts = (state, payload) => {
  return { ...state, ...payload };
};
export const getLogWorkout = (state, payload) => {
  return { ...state, ...payload };
};
export const updateLogWorkout = (state, payload) => {
  return { ...state, ...payload };
};
export const createLogWorkout = (state, payload) => {
  const workout = {
    ...payload,
    date: payload.date
  };
  const loggedWorkouts = state.loggedWorkouts;
  loggedWorkouts.unshift(workout);
  return { ...state, loggedWorkouts };
};

export const deleteLogWorkout = (state, payload) => {
  const newWorkoutsArr = state.loggedWorkouts.filter(
    e => e.workoutId !== payload
  );
  return { ...state, loggedWorkouts: newWorkoutsArr };
};

export default createReducer(initialState, {
  [GET_ALL_WORKOUTS]: getAllWorkouts,
  [GET_LOG_WORKOUT]: getLogWorkout,
  [UPDATE_LOG_WORKOUT]: updateLogWorkout,
  [CREATE_LOG_WORKOUT]: createLogWorkout,
  [DELETE_LOG_WORKOUT]: deleteLogWorkout
});
