import React from "react";
import CalendarDate from "./CalendarDate";
import ScheduleItem from "../schedule/ScheduleItem";
import { connect } from "react-redux";
import { removeSelfFromReservationAction } from "../../../redux/schedule/scheduleActions";
import { openModalAction } from "../../../redux/modals/modalsActions";
import ReservationConfirmationModal from "../modals/schedule/ReservationConfirmationModal";

const TodayInfoContainer = ({
  userReservations,
  removeSelfFromReservationAction,
  openModalAction,
}) => {
  const todayEpoch = new Date().setHours(0, 0, 0, 0);
  const scheduleItemArr = [];
  const modal = {
    modalHeader: "Annuler la réservation",
    modalType: "cancel",
  };
  if (userReservations) {
    userReservations.map((e) => {
      const dateZero = new Date(0);
      dateZero.setUTCSeconds(e.date._seconds);
      const reservationDate = dateZero.toLocaleString("fr");
      const reservationEpoch = dateZero.setHours(0, 0, 0, 0);
      if (reservationEpoch === todayEpoch) {
        scheduleItemArr.push({ e, reservationDate });
      }
      return scheduleItemArr;
    });
  }
  return (
    <div>
      <div className="today-info__content">
        <div className="today-info__left">
          <CalendarDate />
        </div>
        <div className="today-info__right">
          <h3>Aujourd'hui</h3>
          <p>Mes réservations :</p>

          {scheduleItemArr.length === 0 ? (
            <p>Pas de réservations</p>
          ) : (
            scheduleItemArr.map((element) => {
              return (
                <div
                  className="today-info__workout"
                  key={element.e.reservationId}
                >
                  <ScheduleItem
                    wodType={element.e.workoutType}
                    wodName={element.e.workoutName}
                    date={element.reservationDate}
                    click={() =>
                      openModalAction({
                        ...modal,
                        confirmAction: () =>
                          removeSelfFromReservationAction(
                            element.e.reservationId
                          ),
                        modalComponent: (
                          <ReservationConfirmationModal reservation={element} />
                        ),
                      })
                    }
                  />
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

const mapState = (state) => ({
  userReservations: state.schedule.userReservations,
});

const mapActions = {
  removeSelfFromReservationAction,
  openModalAction,
};

export default connect(mapState, mapActions)(TodayInfoContainer);
