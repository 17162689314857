import { createReducer } from "../../utils/reducerUtil";
import { LOG_OUT, LOG_IN, SIGN_UP, RESET_PASSWORD } from "./authConstants";

const initialState = {
  isFetching: true,
  isAuthenticated: false,
  FBIdToken: null
};

export const logIn = (state, payload) => {
  return {
    ...state,
    isAuthenticated: true,
    FBIdToken: payload,
    isFetching: false
  };
};
export const logOut = (state, payload) => {
  return {
    ...state,
    FBIdToken: null,
    isFetching: false,
    isAuthenticated: false
  };
};
export const signUp = (state, payload) => {
  return { ...state, ...payload };
};
export const resetPassword = (state, payload) => {
  return { ...state, ...payload };
};

export default createReducer(initialState, {
  [LOG_IN]: logIn,
  [LOG_OUT]: logOut,
  [SIGN_UP]: signUp,
  [RESET_PASSWORD]: resetPassword
});
