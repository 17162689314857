import React from "react";
// import PropTypes from "prop-types";

const MarketplaceDashboard = props => {
  return (
    <div>
      <h3>Prochainement...</h3>
      <p>Vous aurez accès à un marketplace dédié à votre pratique sportive</p>
    </div>
  );
};

MarketplaceDashboard.propTypes = {};

export default MarketplaceDashboard;
