import React from "react";
// import PropTypes from 'prop-types'
import { connect } from "react-redux";
import { closeModalAction } from "../../../redux/modals/modalsActions";

const Modal = ({
  confirmAction,
  closeModalAction,
  modalType,
  ModalComponent,
  modalHeader,
  formAction,
}) => {
  let btnProps;
  if (!confirmAction && formAction) {
    btnProps = {
      form: "modal-form",
      type: "submit",
    };
  } else if (confirmAction && !formAction) {
    btnProps = {
      onClick: () => {
        confirmAction();
        if (modalType !== "edit") {
          closeModalAction();
        }
      },
    };
  } else if (confirmAction && formAction) {
    btnProps = {
      form: "modal-form",
      type: "submit",
      onClick: () => {
        confirmAction();
        if (modalType !== "edit") {
          closeModalAction();
        }
      },
    };
  }
  return (
    <div className="modal">
      <div className="modal__container">
        {/* Substring added to limit the size of the modal header */}
        <div className="modal__header">
          {modalHeader}
          <button
            onClick={() => closeModalAction()}
            className="modal__header--close-button"
          >
            <i className="fal fa-times"></i>
          </button>
        </div>
        <div className="modal__body">
          {ModalComponent && <ModalComponent.type {...ModalComponent.props} />}
        </div>
        <div className="modal__buttons">
          <button
            className="modal__button--close"
            onClick={() => closeModalAction()}
          >
            FERMER
          </button>
          {modalType && modalType === "cancel" && (
            <button className="modal__button--cancel" {...btnProps}>
              CONFIRMER
            </button>
          )}
          {modalType && modalType === "delete" && (
            <button className="modal__button--cancel" {...btnProps}>
              SUPPRIMER
            </button>
          )}
          {modalType && modalType === "confirm" && (
            <button className="modal__button--confirm" {...btnProps}>
              CONFIRMER
            </button>
          )}
          {modalType && modalType === "edit" && (
            <button className="modal__button--edit" {...btnProps}>
              MODIFIER
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {};

const mapState = (state, props) => ({
  modalType: state.modal.modalType,
  ModalComponent: state.modal.modalComponent,
  modalHeader: state.modal.modalHeader,
  formAction: state.modal.formAction,
  confirmAction: state.modal.confirmAction,
});

const mapActions = {
  closeModalAction,
};

export default connect(mapState, mapActions)(Modal);
