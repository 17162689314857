import React from "react";
import LogHeader from "./LogHeader";
import LogList from "./LogList";
import LogForm from "./LogForm";
// import PropTypes from "prop-types";

class LogMain extends React.Component {
  state = {
    formOpen: false
  };

  onButtonClick = () => {
    this.setState(prevState => ({
      formOpen: !prevState.formOpen
    }));
  };
  onCloseForm = () => {
    this.setState({ formOpen: false });
  };

  render() {
    const { formOpen } = this.state;
    return (
      <div>
        <LogHeader formOpen={formOpen} onButtonClick={this.onButtonClick} />

        {formOpen ? <LogForm onCloseForm={this.onCloseForm} /> : <LogList />}
      </div>
    );
  }
}

LogMain.propTypes = {};

export default LogMain;
