import React from "react";
import { connect } from "react-redux";
// import PropTypes from 'prop-types'

const LogWeek = ({ loggedWorkouts, loggedPrs }) => {
  const modal = {
    modalHeader: "Nouveau PR..",
    formAction: true,
    modalType: "confirm"
  };
  return (
    <div>
      <p>
        <span>{(loggedWorkouts && loggedWorkouts.length) || "0"}</span>
        &nbsp;entrainements enregistrés
      </p>
      <p>
        <span>{(loggedPrs && loggedPrs.length) || "0"}</span>
        &nbsp;records personnels enregistrés
      </p>
      {/* <button>Nouveau PR</button>
      <button>Voir PRs</button> */}
      {/* <p>
        5 <span className="positive">(+2)</span> records personnels (PR)
        enregistrés
      </p> */}
    </div>
  );
};

const mapState = (state, props) => ({
  loggedWorkouts: state.workoutLog.loggedWorkouts,
  loggedPrs: state.workoutLog.loggedPrs
});

LogWeek.propTypes = {};

export default connect(mapState)(LogWeek);
