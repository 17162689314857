import React from "react";
// import PropTypes from "prop-types";
import HomepageNav from "./HomepageNav";
import HamburgerMenu from "./HamburgerMenu";

const HomepageHeader = props => {
  return (
    <div className="homepage__header">
      <div className="homepage__brand">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/helyios-app.appspot.com/o/LogoWY.png?alt=media&token=90913127-e4e5-4bf0-9fef-5875583ae00a"
          className="homepage__brand--logo"
          alt="Helyios Logo"
        />
        <h1 className="homepage__brand--title">Helyios</h1>
      </div>

      <HomepageNav />
      <HamburgerMenu />
    </div>
  );
};

HomepageHeader.propTypes = {};

export default HomepageHeader;

// TODO : Homepage header => Brand + Menu (NavLink)
