import { createReducer } from "../../utils/reducerUtil";
import {
  GET_ALL_MEMBERS,
  CREATE_NEW_MEMBER,
  UPDATE_MEMBER_DETAILS
  //   GET_MEMBER_DETAILS,
  //   UPDATE_MEMBER_DETAILS,

  //   SET_MEMBER_PAYMENT,
  //   SET_MEMBER_SUBSCRIPTION
} from "./membersConstants";

const initialState = {};

export const getAllMembers = (state, payload) => {
  return { ...state, ...payload };
};

export const createNewMember = (state, payload) => {
  const newUser = {
    ...payload.userCredentials,
    birthDate: {
      _seconds: new Date(payload.userCredentials.birthDate).valueOf() / 1000
    },
    createdAt: {
      _seconds: new Date().valueOf() / 1000
    },
    createdPassword: payload.newUserPassword
  };
  return { ...state, boxMembers: [...state.boxMembers, newUser] };
};

export const updateMember = (state, payload) => {
  const newMembersArr = state.boxMembers.filter(
    e => e.userId !== payload.userId
  );
  const updatedMember = {
    ...payload,
    birthDate: {
      _seconds: new Date(payload.birthDate).valueOf() / 1000
    }
  };

  return { ...state, boxMembers: [...newMembersArr, updatedMember] };
};

export default createReducer(initialState, {
  [GET_ALL_MEMBERS]: getAllMembers,
  [CREATE_NEW_MEMBER]: createNewMember,
  [UPDATE_MEMBER_DETAILS]: updateMember
});
