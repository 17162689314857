// Modal de confirmation pour inscription à la réservation ou pour l'annulation

import React from "react";
import CalendarDate from "../../dashboard/CalendarDate";
import ScheduleItem from "../../schedule/ScheduleItem";
// import PropTypes from "prop-types";

const ReservationConfirmationModal = ({
  reservation,
  modalType,
  date,
  activeReservation
}) => {
  return (
    <React.Fragment>
      <CalendarDate date={date && date} />
      <ScheduleItem
        wodType={reservation.e.workoutType}
        wodName={reservation.e.workoutName}
        date={reservation.reservationDate}
      />
      {/* TODO : CHANGE Waitlistusers with registeredUsers.length */}
      <div className="modal__body--text">
        {activeReservation &&
          activeReservation[0] &&
          activeReservation[0].registeredUsers.length >
            activeReservation[0].capacity &&
          activeReservation[0].waitlistUsers.length <
            activeReservation[0].waitlistCapacity && (
            <p>Voulez vous vous inscrire en file d'attente ?</p>
          )}
        {activeReservation &&
        activeReservation[0] &&
        activeReservation[0].registeredUsers.length >
          activeReservation[0].capacity ? (
          <p>Réservation complète</p>
        ) : (
          <p>
            Êtes-vous certain de vouloir{" "}
            {modalType === "cancel"
              ? "annuler la réservation"
              : "confirmer la réservation"}{" "}
            ?
          </p>
        )}
      </div>
    </React.Fragment>
  );
};

ReservationConfirmationModal.propTypes = {};

export default ReservationConfirmationModal;
