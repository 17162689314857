import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { closeRespMenu } from "../../../redux/nav/navActions";
// import PropTypes from "prop-types";

class Profile extends React.Component {
  render() {
    const { user, signOut, open, closeRespMenu } = this.props;

    return (
      <div className="profile">
        <img src={user && user.imageUrl} alt="Profil" />
        <div className="name-profile">
          {user && user.profileType === "athlete"
            ? `${user.firstName} ${user.lastName}`
            : user.boxName}
        </div>
        <div className="edit-profile">
          <Link
            onClick={open ? () => closeRespMenu() : null}
            to="/edit-profile"
          >
            Modifier le profil
          </Link>
        </div>
        <div className="log-out-profile">
          <Link onClick={() => signOut()} to="/">
            Déconnexion
          </Link>
        </div>
      </div>
    );
  }
}

const mapState = (state, props) => ({
  open: state.responsive.open,
});

const mapActions = {
  closeRespMenu,
};

export default connect(mapState, mapActions)(Profile);
