import React from "react";
// import PropTypes from "prop-types";
import CalendarDate from "../../dashboard/CalendarDate";
import ScheduleItem from "../../schedule/ScheduleItem";
import { dateUtil } from "../../../../utils/dateUtil";
import { connect } from "react-redux";
import ReservationMembersItem from "../../members/ReservationMembersItem";
import AddMembersToReservationModal from "./AddMembersToReservationModal";
import {
  addUserToReservationAction,
  deleteUserFromReservationAction,
} from "../../../../redux/schedule/scheduleActions";
import { openModalAction } from "../../../../redux/modals/modalsActions";

// TODO : Ajouter annulation de réservation (pour jours fériés ou vacances)
// Ceci ajoutera une option "cancelled" dans firestore ce qui annulera la réservation

class ReservationDetailsModal extends React.Component {
  state = {
    showMembers: false,
    showWaitlist: false,
  };
  toggleMembers = () => {
    this.setState((prevState) => ({
      showMembers: !prevState.showMembers,
    }));
  };

  toggleWaitlist = () => {
    this.setState((prevState) => ({
      showWaitlist: !prevState.showWaitlist,
    }));
  };

  render() {
    const {
      resa,
      wodName,
      wodType,
      date,
      members,
      openModalAction,
      deleteUserFromReservationAction,
      newResa,
      activeReservations,
    } = this.props;
    const { showMembers, showWaitlist } = this.state;

    let modal, activeResa;
    if (resa) {
      activeResa = activeReservations.find(
        (e) => e.reservationId === resa.reservationId
      );
      modal = {
        modalHeader: `Ajouter un membre au ${resa.workoutType}`,
        modalComponent: (
          <AddMembersToReservationModal members={members} resa={resa} />
        ),
      };
    } else {
      modal = {
        modalHeader: `Ajouter un membre au ${newResa.workoutType}`,
        modalComponent: (
          <AddMembersToReservationModal members={members} resa={newResa} />
        ),
      };
    }

    let reservationDate,
      cancelled = false;
    if (resa) {
      reservationDate = dateUtil(resa.date);
      if (resa.cancelled) {
        cancelled = true;
      }
    } else {
      reservationDate = newResa.date;
    }
    return (
      <div className="reservation-details__container">
        <div className="reservation-detail__container--left">
          <CalendarDate
            date={resa ? reservationDate.toLocaleString("fr") : date}
          />
          <ScheduleItem
            date={resa ? reservationDate.toLocaleString("fr") : date}
            wodType={wodType}
            wodName={wodName}
          />
        </div>
        <div className="reservation-detail__container--right">
          {cancelled && (
            <div className="reservation-details__line">
              <strong>
                <i className="fal fa-exclamation-triangle"></i> Réservation
                annulée <i className="fal fa-exclamation-triangle"></i>
              </strong>
            </div>
          )}
          {(!resa ||
            activeResa.registeredUsers.length <
              activeResa.capacity + resa.waitlistCapacity - 1) &&
            reservationDate > new Date() && (
              <button
                onClick={() => openModalAction(modal)}
                className={`reservation-details__line--button-add${
                  resa &&
                  activeResa.registeredUsers.length >= activeResa.capacity
                    ? "wait"
                    : ""
                }`}
              >
                Ajouter un membre
              </button>
            )}
          <div className="reservation-details__line">
            <div className="reservation-details__line-always-visible">
              <span>
                Membres inscrits :{" "}
                {resa ? activeResa.registeredUsers.length : "0"}
              </span>
              {resa && activeResa.registeredUsers.length > 0 && (
                <button
                  onClick={() => this.toggleMembers()}
                  className="reservation-details__line--button"
                >
                  {showMembers ? (
                    <i className="fal fa-chevron-down"></i>
                  ) : (
                    <i className="fal fa-chevron-right"></i>
                  )}
                </button>
              )}
            </div>

            {showMembers &&
              resa &&
              activeResa.registeredUsers &&
              activeResa.registeredUsers.map((user, i) => (
                <div key={i}>
                  {members.map((e) => {
                    if (e.userId === user) {
                      return (
                        <ReservationMembersItem
                          member={e}
                          resa={resa}
                          key={user}
                          action="delete"
                          click={() =>
                            deleteUserFromReservationAction(
                              resa.reservationId,
                              e.userId
                            )
                          }
                        />
                      );
                    } else return null;
                  })}
                </div>
              ))}
          </div>
          <div className="reservation-details__line">
            <span>
              Membres en liste d'attente :{" "}
              {resa && resa.registeredUsers.length > resa.capacity
                ? resa.registeredUsers.length - resa.capacity
                : "0"}
            </span>

            {resa && resa.registeredUsers.length > resa.capacity && (
              <button
                onClick={() => this.toggleWaitlist()}
                className="reservation-details__line--button"
              >
                {showWaitlist ? (
                  <i className="fal fa-chevron-down"></i>
                ) : (
                  <i className="fal fa-chevron-right"></i>
                )}
              </button>
            )}
          </div>
          {!cancelled && reservationDate > new Date() && (
            <div className="reservation-details__line">
              {/* TODO : Add button change if reservation is cancelled already */}
              <button className="reservation-details__line--cancel-button">
                Annuler la réservation
              </button>
            </div>
          )}
          {console.log({ resa, activeResa })}
          {/* activeResa = resa = undefined when no resa 
              want to check if resa and activeResa and resa is cancelled
          */}
          {reservationDate > new Date() && cancelled && (
            <div className="reservation-details__line">
              <button className="reservation-details__line--cancel-button-restore">
                Restaurer la réservation
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

ReservationDetailsModal.propTypes = {};

const mapState = (state, props) => ({
  members: state.members.boxMembers,
  activeReservations: state.schedule.activeReservations,
});

const mapActions = {
  addUserToReservationAction,
  openModalAction,
  deleteUserFromReservationAction,
};

export default connect(mapState, mapActions)(ReservationDetailsModal);
