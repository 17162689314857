import React from "react";
// import PropTypes from "prop-types";
import { Field, Form, Formik } from "formik";
import { connect } from "react-redux";
import { updateUserAction } from "../../../redux/user/userActions";
import { openModalAction } from "../../../redux/modals/modalsActions";
import UploadUserImageModal from "../../components/modals/members/UploadUserImageModal";

class EditProfilePage extends React.Component {
  render() {
    const { user, updateUserAction, openModalAction } = this.props;
    let initialValues;
    const modal = {
      modalHeader: "Changer d'image",
      modalComponent: <UploadUserImageModal />
    };
    if (user && user.profileType === "athlete") {
      initialValues = {
        firstName: user.firstName,
        lastName: user.lastName,
        birthDate: new Date(user.birthDate._seconds * 1000)
          .toISOString()
          .substr(0, 10),
        height: user.height,
        weight: user.weight
      };
    } else if (user && user.profileType === "box") {
      initialValues = {
        boxName: user.boxName,
        phoneNumber: user.phoneNumber
      };
    }
    initialValues = {
      ...initialValues,
      address: user.address,
      postalCode: user.postalCode,
      city: user.city,
      boxCenterId: user.boxCenterId,
      email: user.email,
      imageUrl: user.imageUrl
    };

    return (
      <div className="edit-profile-page__container">
        <span className="edit-profile-page__title">Modifier le profil</span>
        <div className="edit-profile-image__container">
          <img className="edit-profile-image__img" src={user.imageUrl} alt="" />
          <button onClick={() => openModalAction(modal)}>
            <i className="far fa-edit"></i>&nbsp; Changer d'image de profil
          </button>
        </div>
        <Formik
          initialValues={initialValues}
          onSubmit={values => {
            let userData;
            if (user && user.profileType === "athlete") {
              userData = {
                email: values.email,
                firstName: values.firstName,
                lastName: values.lastName,
                birthDate: values.birthDate,
                address: values.address,
                postalCode: values.postalCode,
                city: values.city,
                weight: values.weight,
                height: values.height,
                phoneNumber: values.phoneNumber
              };
              updateUserAction(userData);
            } else if (user && user.profileType === "box") {
              userData = {
                email: values.email,
                boxName: values.boxName,
                address: values.address,
                postalCode: values.postalCode,
                city: values.city,
                phoneNumber: values.phoneNumber
              };
              updateUserAction(userData);
            }
          }}
        >
          <Form>
            <div className="edit-profile-page__form--line">
              <div className="edit-profile-page__form--element">
                <label htmlFor="email">Adresse Email</label>
                <br />
                <Field type="email" name="email" placeholder="Email" />
              </div>
              {user && user.profileType === "box" ? (
                <React.Fragment>
                  <div className="edit-profile-page__form--element">
                    <label htmlFor="boxName">Nom de la box</label>
                    <br />
                    <Field type="text" name="boxName" placeholder="Box" />{" "}
                  </div>
                  <div className="edit-profile-page__form--element">
                    <label htmlFor="phoneNumber">Numéro de téléphone</label>
                    <br />
                    <Field
                      type="text"
                      name="phoneNumber"
                      placeholder="Numéro de tel"
                    />
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="edit-profile-page__form--element">
                    <label htmlFor="firstName">Prénom</label>
                    <br />
                    <Field type="text" name="firstName" placeholder="Prénom" />
                  </div>
                  <div className="edit-profile-page__form--element">
                    <label htmlFor="lastName">Nom</label>
                    <br />
                    <Field type="text" name="lastName" placeholder="Nom" />
                  </div>
                </React.Fragment>
              )}
            </div>
            <div className="edit-profile-page__form--line">
              <div className="edit-profile-page__form--element">
                <label htmlFor="address">Adresse</label>
                <br />
                <Field type="text" name="address" placeholder="Adresse" />
              </div>
              <div className="edit-profile-page__form--element">
                <label htmlFor="postalCode">Code Postal</label>
                <br />
                <Field
                  type="text"
                  name="postalCode"
                  placeholder="Code Postal"
                />
              </div>
              <div className="edit-profile-page__form--element">
                <label htmlFor="city">Ville</label>
                <br />
                <Field type="text" name="city" placeholder="Ville" />
              </div>
            </div>
            {user && user.profileType === "athlete" && (
              <div className="edit-profile-page__form--line">
                <div className="edit-profile-page__form--element">
                  <label htmlFor="birthDate">Date de naissance</label>
                  <br />
                  <Field
                    type="date"
                    name="birthDate"
                    placeholder="Date de naissance"
                  />
                </div>
                <div className="edit-profile-page__form--element">
                  <label htmlFor="weight">Poids</label>
                  <br />
                  <Field type="number" name="weight" placeholder="Poids (kg)" />
                </div>
                <div className="edit-profile-page__form--element">
                  <label htmlFor="height">Taille</label>
                  <br />
                  <Field
                    type="number"
                    name="height"
                    placeholder="Taille (cm)"
                  />
                </div>
              </div>
            )}
            <button className="edit-profile-page__form--button" type="submit">
              Editer mon profil
            </button>
          </Form>
        </Formik>
      </div>
    );
  }
}

// TODO : Remove BR and use styling
// TODO : Add the image upload feature
// TODO : Take care of boxCenterId (how to modify it ? Via edit profile or send request to box ?)

const mapState = state => ({
  user: state.user
});

const actions = { updateUserAction, openModalAction };

export default connect(mapState, actions)(EditProfilePage);
