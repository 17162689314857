import React from "react";
// import PropTypes from "prop-types";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { logInAction } from "../../redux/auth/authActions";
import HomepageHeader from "../components/nav/HomepageHeader";
import ResponsiveHomepage from "../components/nav/ResponsiveHomepage";

const LoginPage = ({ logInAction, history, loading }) => {
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      const userData = {
        email: values.email,
        password: values.password,
      };
      logInAction(userData, history);
    },
  });
  return (
    <div className="login-page__container">
      <ResponsiveHomepage />
      <HomepageHeader />
      <div className="login-page__form">
        <h3>Connexion</h3>
        {loading ? (
          <div className="loader"></div>
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <input
              type="email"
              name="email"
              placeholder="Email"
              onChange={formik.handleChange}
              value={formik.values.email}
            />
            <br />
            <input
              type="password"
              name="password"
              placeholder="Mot de Passe"
              onChange={formik.handleChange}
              value={formik.values.password}
            />
            <br />
            <button type="submit">Se connecter</button>
          </form>
        )}
      </div>
    </div>
  );
};

const mapState = (state) => ({
  loading: state.async.loading,
});

const actions = {
  logInAction,
};

export default connect(mapState, actions)(LoginPage);
