import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

const ScheduleItem = ({
  wodName,
  wodType,
  date,
  click,
  resa,
  passed,
  profileType,
}) => {
  const [wodTime, setWodTime] = useState(null);
  useEffect(() => {
    if (date.length > 4) {
      const dateSplit = date.split(" ")[1];
      const hoursSplit = dateSplit.split(":");
      setWodTime(`${hoursSplit[0]}:${hoursSplit[1]}`);
    } else {
      setWodTime(`${date.substring(0, 2)}:${date.substring(2, 4)}`);
    }
  }, [date]);

  return (
    <button
      type="button"
      className={
        passed ? "schedule-element free" : `schedule-element ${wodType}`
      }
      onClick={() => click()}
    >
      &nbsp;&nbsp;
      <strong>{wodName}</strong> - {wodTime}
      {resa &&
        date &&
        resa.registeredUsers.length > 0 &&
        profileType &&
        profileType === "box" && (
          <span className="schedule-element--members">
            <i className="far fa-user"></i>
            &nbsp;
            {resa && resa.registeredUsers.length}
          </span>
        )}
    </button>
  );
};

const mapState = (state, props) => ({
  profileType: state.user.profileType,
});

export default connect(mapState)(ScheduleItem);
