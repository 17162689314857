import React, { Component } from "react";
import HomepageHeader from "../components/nav/HomepageHeader";
// import PropTypes from "prop-types";
import ResponsiveHomepage from "../components/nav/ResponsiveHomepage";

export class Homepage extends Component {
  static propTypes = {};

  render() {
    return (
      <React.Fragment>
        <div className="homepage__container">
          <ResponsiveHomepage />
          <HomepageHeader />

          <div className="homepage__content--fullscreen">
            <div className="homepage__title">
              Helyios, la solution de gestion pour votre salle de sport <br />
              qui avantage vos athlètes
            </div>
          </div>
          <object
            type="image/svg+xml"
            data="/img/schedule.svg"
            className="homepage__schedule"
          >
            Image planning
          </object>
          <div className="homepage__content--features">
            <h3>FONCTIONNALIT&Eacute;S</h3>
            <div className="homepage__content--features_column">
              <h3>
                <i className="fal fa-dumbbell"></i> Pour les salles de sport
              </h3>
              Logiciel de gestion pour les salles de sport :
              <ul>
                <li>
                  <i className="far fa-check"></i>Gestion des adhérents
                </li>
                <li>
                  <i className="far fa-check"></i>Gestion du planning et des
                  réservations
                </li>
                <li>
                  <i className="far fa-check"></i>Envoi de message centralisé
                </li>
                <li>
                  <i className="far fa-check"></i>Une application pour faciliter
                  l'encadrement par les coachs
                </li>
                <li>
                  <i className="far fa-check"></i>Un outil de programmation de
                  wods
                </li>
                <li>
                  <i className="far fa-check"></i>Préparation de plans de cours
                  accessibles aux coachs
                </li>
              </ul>
            </div>
            <div className="homepage__content--features_column">
              <h3>
                <i className="fal fa-users"></i> Pour les athlètes
              </h3>
              Outil de suivi dédié à la santé et à la performance des athlètes :
              <ul>
                <li>
                  <i className="far fa-check"></i>Système de réservation simple
                </li>
                <li>
                  <i className="far fa-check"></i>Accès aux annonces importantes
                  de la salle
                </li>
                <li>
                  <i className="far fa-check"></i>Cahier d'entrainement pour
                  suivre l'évolution de ses performances
                </li>
                <li>
                  <i className="far fa-check"></i>Suivi des métriques de santé
                  au cours de la pratique sportive
                </li>
                <li>
                  <i className="far fa-check"></i>Outil de programmation des
                  séances d'entrainement
                </li>
                <li>
                  <i className="far fa-check"></i>Accès à un système de
                  compétition en ligne
                </li>
              </ul>
            </div>
          </div>
          <div className="homepage__content--fullscreen">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/helyios-app.appspot.com/o/LogoWY.png?alt=media&token=90913127-e4e5-4bf0-9fef-5875583ae00a"
              alt="Logo Helyios"
            />
          </div>
          <div className="homepage__footer">
            <div className="homepage__footer--socials">
              <a
                href="https://www.instagram.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-instagram"></i>
              </a>
              <a
                href="https://www.facebook.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook"></i>
              </a>
              <a
                href="https://www.youtube.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-youtube"></i>
              </a>
            </div>
          </div>
          <div className="homepage__footer--text">
            Copyright ® Nicolas Ruelle 2019 - Tous droits réservés
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Homepage;
