import {
  LOG_IN,
  LOG_OUT
  // SIGN_UP, RESET_PASSWORD
} from "./authConstants";
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from "../async/asyncActions";
import { getUserAction, removeUserAction } from "../user/userActions";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { toastr } from "react-redux-toastr";

export const logInAction = (userData, history) => {
  return async (dispatch, getState) => {
    try {
      dispatch(asyncActionStart());
      const res = await axios.post("/login", userData);
      toastr.success("Connexion réussie");
      setAuthorization(res.data.token);
      await dispatch(getUserAction());
      dispatch({ type: LOG_IN, payload: res.data.token });
      history.push("/dashboard");
      dispatch(asyncActionFinish());
    } catch (error) {
      console.log({ ...error });
      dispatch(asyncActionError());
      toastr.error("Echec de la connexion", "Veuillez réessayer");
    }
  };
};

// TODO : CHANGE THE ASYNC ACTIONS FROM AUTH TO USER

const setAuthorization = token => {
  const FBIdToken = `Bearer ${token}`;
  localStorage.setItem("FBIdToken", FBIdToken);
  axios.defaults.headers.common["Authorization"] = FBIdToken;
};

export const logOutAction = () => async dispatch => {
  try {
    dispatch(asyncActionStart());
    localStorage.removeItem("FBIdToken");
    delete axios.defaults.headers.common["Authorization"];
    await dispatch(removeUserAction());
    toastr.success("Vous êtes déconnecté");
    await dispatch({ type: LOG_OUT });
    dispatch(asyncActionFinish());
  } catch (error) {
    dispatch(asyncActionError());
  }
};

export const signUpAction = async () => {};

export const resetPasswordAction = async () => {};

// Add dispatch if it's not working

export const checkUserAuth = () => {
  return async dispatch => {
    const token = localStorage.FBIdToken;
    if (token) {
      const decodedToken = jwt_decode(token);
      if (decodedToken.exp * 1000 > Date.now()) {
        axios.defaults.headers.common["Authorization"] = token;
        await dispatch(getUserAction());
        await dispatch({ type: LOG_IN, payload: token });
      } else {
        localStorage.removeItem("FBIdToken");
        delete axios.defaults.headers.common["Authorization"];
        await dispatch(removeUserAction());
        await dispatch({ type: LOG_OUT });
      }
    } else {
      await dispatch(removeUserAction());
      await dispatch({ type: LOG_OUT });
    }
  };
};
