import React from "react";
import { Field, FieldArray } from "formik";
import { dayCapMin } from "../../../../utils/daysUtil";
// import PropTypes from 'prop-types'

// TODO : Séparer les jours des input pour faire de belles bordures

const ScheduleSetterTableItem = (props) => {
  return (
    <div className="workout-scheduler__table--item">
      <span className="day">{dayCapMin[props.day]}</span>
      <FieldArray
        name={`times[${props.day}]`}
        render={(arrayHelpers) => (
          <div>
            {props.values.times[props.day] &&
              props.values.times[props.day].map((time, index) => {
                return (
                  <div
                    className="workout-scheduler__table--item-time"
                    key={index}
                  >
                    <Field type="text" name={`times[${props.day}][${index}]`} />

                    <button
                      type="button"
                      className="workout-scheduler__table--item-button"
                      onClick={() => arrayHelpers.remove(index)}
                    >
                      <i className="fal fa-times-circle"></i>
                    </button>
                  </div>
                );
              })}
            <button
              className="workout-scheduler__table--item-add-button"
              type="button"
              disabled={
                props.values.times[props.day][
                  props.values.times[props.day].length - 1
                ] > "2200"
                  ? true
                  : false
              }
              onClick={() => {
                const times = props.values.times[props.day];
                let newTime;
                if (times.length !== 0 && props.values.duration <= 60) {
                  newTime = Number(times[times.length - 1]) + 100;
                  if (newTime < 1000) {
                    newTime = `0${newTime}`;
                  } else {
                    newTime = `${newTime}`;
                  }
                } else if (times.length !== 0 && props.values.duration <= 90) {
                  if (newTime.slice(2, 4) === "30") {
                    newTime = Number(times[times.length - 1]) + 170;
                  } else {
                    newTime = Number(times[times.length - 1]) + 130;
                  }
                  if (newTime < 1000) {
                    newTime = `0${newTime}`;
                  } else {
                    newTime = `${newTime}`;
                  }
                } else {
                  newTime = "0600";
                }
                arrayHelpers.push(`${newTime}`);
              }}
            >
              Ajouter un créneau
            </button>
          </div>
        )}
      />
    </div>
  );
};

ScheduleSetterTableItem.propTypes = {};

export default ScheduleSetterTableItem;
