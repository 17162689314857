import React, { Component } from "react";
// import PropTypes from 'prop-types'
import { connect } from "react-redux";
import { Formik, Form, Field } from "formik";
import ScheduleSetterTableItem from "./ScheduleSetterTableItem";
import { closeModalAction } from "../../../../redux/modals/modalsActions";

// TODO : ADD YUP validation so that it only accepts HHMM or HH:MM
// TODO : ORDER times asc

class ScheduleSetterTableModal extends Component {
  state = { shouldRender: true };
  componentDidMount() {
    if (window.innerWidth < 755 && this.state.shouldRender) {
      this.setState({
        shouldRender: false,
      });
    }
  }
  render() {
    const { schedule, workoutType, closeModalAction } = this.props;
    const { shouldRender } = this.state;
    let initialValues = {
      duration: 0,
      capacity: 0,
      waitlistCapacity: 0,
      times: {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
      },
    };
    if (schedule[workoutType]) {
      initialValues = { ...schedule[workoutType] };
    }
    if (shouldRender) {
      return (
        <div className="workout-scheduler__container">
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
              console.log(values);
              closeModalAction();
            }}
          >
            {(props) => (
              <Form id="modal-form">
                <div className="workout-scheduler__settings">
                  <div className="workout-scheduler__settings--item">
                    <label htmlFor="duration">Durée (minutes)</label>
                    <Field name="duration" />
                  </div>
                  <div className="workout-scheduler__settings--item">
                    <label htmlFor="capacity">Capacité (# membres)</label>
                    <Field name="capacity" />
                  </div>
                  <div className="workout-scheduler__settings--item">
                    <label htmlFor="waitlistCapacity">
                      Capacité liste d'attente
                    </label>
                    <Field name="waitlistCapacity" />
                  </div>
                </div>

                {/* FieldArray with inputs looking like reservations on each day */}
                <div className="workout-scheduler__table">
                  <ScheduleSetterTableItem day={1} values={props.values} />
                  <ScheduleSetterTableItem day={2} values={props.values} />
                  <ScheduleSetterTableItem day={3} values={props.values} />
                  <ScheduleSetterTableItem day={4} values={props.values} />
                  <ScheduleSetterTableItem day={5} values={props.values} />
                  <ScheduleSetterTableItem day={6} values={props.values} />
                  <ScheduleSetterTableItem day={0} values={props.values} />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      );
    } else {
      return (
        <div className="warning-message">
          <i className="fal fa-exclamation-circle"></i>
          <span>
            Pour une utilisation idéale et pour pouvoir profiter pleinement de
            cette fonctionnalité, cette page est accessible seulement depuis un
            ordinateur. <br />
            <br />
            Si le problème persiste même depuis un ordinateur, veuillez
            contacter nruelle@helyios.com
          </span>
        </div>
      );
    }
  }
}

const mapState = (state, props) => ({
  schedule: state.schedule.workoutSchedule,
});

const mapActions = {
  closeModalAction,
};

export default connect(mapState, mapActions)(ScheduleSetterTableModal);
