import React, { Component } from "react";
// import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import ScheduleDashboard from "../../components/dashboard/ScheduleDashboard";
import LogDashboard from "../log/LogDashboard";
import DashContainer from "../../components/DashContainer";
import CompetitionDashboard from "../competition/CompetitionDashboard";
import MarketplaceDashboard from "../marketplace/MarketplaceDashboard";
import MembersDashboard from "../members/MembersDashboard";
import EditProfilePage from "./EditProfilePage";
import SidePanel from "../../components/nav/SidePanel";
import SettingsPage from "./SettingsPage";
import { logOutAction } from "../../../redux/auth/authActions";
import HomeDashboard from "./HomeDashboard";
import { getBoxMessagesAction } from "../../../redux/messages/messagesActions";
import {
  getUserReservationsAction,
  getFullScheduleAction
} from "../../../redux/schedule/scheduleActions";
import { getAllWorkoutsAction } from "../../../redux/log/logActions";
import { getAllMembersAction } from "../../../redux/members/membersActions";
import BoxScheduleDashboard from "../schedule/BoxScheduleDashboard";
import WodApp from "../wod-app/WodApp";
import LessonPlan from "../lesson-plan/LessonPlan";
import WodBuilder from "../wod-builder/WodBuilder";
import PaymentsPage from "../payments/PaymentsPage";
import { getLastHealthLogAction } from "../../../redux/health/healthActions";

export class Dashboard extends Component {
  async componentDidMount() {
    await this.props.getBoxMessagesAction();
    if (this.props.user.profileType === "athlete") {
      await this.props.getUserReservationsAction();
      await this.props.getAllWorkoutsAction();
      await this.props.getLastHealthLogAction();
    } else if (this.props.user.profileType === "box") {
      await this.props.getAllMembersAction();
      await this.props.getFullScheduleAction();
    }
  }

  static propTypes = {};

  render() {
    const { user, auth } = this.props;
    if (auth && auth.isAuthenticated === false) {
      return <Redirect to="/login" />;
    } else if (auth && auth.isAuthenticated) {
      return (
        <div>
          <div className="header" />
          <SidePanel user={user} signOut={this.props.signout} />
          <div className="container-dashboard">
            <Switch>
              <Route path="/dashboard" component={HomeDashboard} />
              <Route
                path="/planning"
                render={() => (
                  <DashContainer
                    Component={ScheduleDashboard}
                    styleClassName="schedule-dashboard__container--full"
                  />
                )}
              />
              <Route path="/schedule" component={BoxScheduleDashboard} />
              <Route path="/log" component={LogDashboard} />
              <Route
                path="/edit-profile"
                render={() => (
                  <DashContainer
                    Component={EditProfilePage}
                    styleClassName="edit-profile-page__container--full"
                  />
                )}
              />
              <Route path="/members" component={MembersDashboard} />
              <Route path="/marketplace" component={MarketplaceDashboard} />
              <Route path="/competition" component={CompetitionDashboard} />
              <Route path="/paiements" component={PaymentsPage} />
              <Route path="/wod-builder" component={WodBuilder} />
              <Route path="/lesson-plan" component={LessonPlan} />
              <Route path="/wod-app" component={WodApp} />
              <Route path="/settings" component={SettingsPage} />
            </Switch>
          </div>
        </div>
      );
    }
  }
}

const mapState = (state, props) => ({
  auth: state.auth,
  user: state.user,
  log: state.log,
  modal: state.modal
});

const actions = {
  signout: logOutAction,
  getBoxMessagesAction,
  getUserReservationsAction,
  getAllWorkoutsAction,
  getAllMembersAction,
  getFullScheduleAction,
  getLastHealthLogAction
};

export default connect(mapState, actions)(Dashboard);
