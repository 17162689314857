import {
  GET_ALL_MEMBERS,
  CREATE_NEW_MEMBER,
  UPDATE_MEMBER_DETAILS
  //   GET_MEMBER_DETAILS,
  //   SET_MEMBER_PAYMENT,
  //   SET_MEMBER_SUBSCRIPTION
} from "./membersConstants";
// import { toastr } from "react-redux-toastr";
import axios from "axios";
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from "../async/asyncActions";
import { toastr } from "react-redux-toastr";

export const getAllMembersAction = () => async dispatch => {
  console.log("get all members action fired");
  try {
    dispatch(asyncActionStart());
    const res = await axios.get("/box/members");
    dispatch({ type: GET_ALL_MEMBERS, payload: res.data });
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

export const createMemberAction = member => async dispatch => {
  console.log("create member action fired", member);
  try {
    const newMember = {
      ...member
    };
    const res = await axios.post("/box/members/add", newMember);
    console.log(res.data);
    dispatch({ type: CREATE_NEW_MEMBER, payload: res.data });
    toastr.success("Nouveau membre créé avec succès");
  } catch (error) {
    console.log(error);
    toastr.error(
      "Échec de la création du nouveau membre",
      "Veuillez réessayer. Si le problème persiste, contactez un administrateur"
    );
  }
};

export const updateMemberAction = (member, userId) => async dispatch => {
  console.log("update member action fired", member);
  try {
    const memberupdated = {
      ...member
    };
    await axios.post(`/box/member/${userId}`, memberupdated);
    dispatch({ type: UPDATE_MEMBER_DETAILS, payload: memberupdated });
    toastr.success("Profil modifié avec succès");
  } catch (error) {
    console.log(error);
    toastr.error(
      "Échec de la modification du profil",
      "Veuillez réessayer. Si le problème persiste, contactez un administrateur"
    );
  }
};
