import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { openRespMenu, closeRespMenu } from "../../../redux/nav/navActions";

const HamburgerMenu = ({ open, closeMenu, openMenu }) => (
  <div
    role="link"
    onKeyPress={null}
    onClick={open ? closeMenu : openMenu}
    className={open ? "ham-menu open-ham" : "ham-menu"}
    tabIndex="0"
  >
    <span className="bar-1" />
    <span className="bar-2" />
    <span className="bar-3" />
  </div>
);

HamburgerMenu.defaultProps = {
  open: false
};

HamburgerMenu.propTypes = {
  open: PropTypes.bool,
  closeMenu: PropTypes.func.isRequired,
  openMenu: PropTypes.func.isRequired
};

const mapState = state => ({
  open: state.responsive.open
});

const actions = {
  openMenu: openRespMenu,
  closeMenu: closeRespMenu
};

export default connect(mapState, actions)(HamburgerMenu);
