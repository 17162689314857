import React from "react";
// import { Formik, Form, Field, FieldArray } from "formik";
import { connect } from "react-redux";
// import PropTypes from "prop-types";
import WorkoutSchedulerButton from "./WorkoutSchedulerButton";

class DefaultSchedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedWorkout: "wod"
    };
  }
  workoutChange = e => {
    this.setState({ selectedWorkout: e.target.value });
  };
  render() {
    // const { schedule } = this.props;
    return (
      <div>
        <h3>Planning</h3>
        <WorkoutSchedulerButton workoutName="WOD" workoutType="wod" />
        <WorkoutSchedulerButton workoutName="Gym" workoutType="gym" />
        <WorkoutSchedulerButton workoutName="Conditionning" workoutType="aec" />
        <WorkoutSchedulerButton workoutName="Haltérophilie" workoutType="wlt" />
        <WorkoutSchedulerButton workoutName="Compétition" workoutType="comp" />
        <WorkoutSchedulerButton workoutName="Yoga" workoutType="yog" />
        <WorkoutSchedulerButton workoutName="Mobilité" workoutType="mob" />
        <WorkoutSchedulerButton workoutName="Kids" workoutType="kids" />
        <WorkoutSchedulerButton workoutName="Strongman" workoutType="str" />
      </div>
    );
  }
}

DefaultSchedule.propTypes = {};

const mapState = (state, props) => ({
  schedule: state.schedule.workoutSchedule
});

const mapActions = {};

export default connect(mapState, mapActions)(DefaultSchedule);
