export const dateUtil = date => {
  const dateZero = new Date(0);
  if (date._seconds) {
    dateZero.setUTCSeconds(date._seconds);
    return new Date(dateZero);
  }
};

export const toTimestamp = date => {
  return { _seconds: date.valueOf() / 1000 };
};
