import React from "react";
import DashContainer from "../../components/DashContainer";
import ScheduleDashboard from "../../components/dashboard/ScheduleDashboard";
import DefaultSchedule from "../../components/schedule/DefaultSchedule";
// import PropTypes from 'prop-types'

const BoxScheduleDashboard = props => {
  return (
    <React.Fragment>
      <DashContainer
        Component={DefaultSchedule}
        styleClassName="default-schedule__container"
      />
      <DashContainer
        Component={ScheduleDashboard}
        styleClassName="schedule-dashboard__container"
      />
    </React.Fragment>
  );
};

BoxScheduleDashboard.propTypes = {};

export default BoxScheduleDashboard;
