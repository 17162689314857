import React from "react";
// import PropTypes from "prop-types";

const CompetitionDashboard = props => {
  return (
    <div>
      <h3>Prochainement...</h3>
      <p>Ici, vous pourrez vous inscrire et/ou organiser des compétitions </p>
    </div>
  );
};

CompetitionDashboard.propTypes = {};

export default CompetitionDashboard;
