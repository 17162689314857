import {
  GET_FULL_SCHEDULE,
  DELETE_USER_FROM_RESERVATION,
  GET_USER_RESERVATIONS,
  DELETE_SELF_FROM_RESERVATION,
  ADD_USER_TO_RESERVATION,
  ADD_SELF_TO_RESERVATION,
  CREATE_NEW_RESERVATION,
  CREATE_NEW_RESERVATION_SELF,
  CANCEL_NEW_RESERVATION,
  CANCEL_EXISTING_RESERVATION,
  UNCANCEL_RESERVATION,
} from "./scheduleConstants";
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError,
} from "../async/asyncActions";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { toTimestamp } from "../../utils/dateUtil";

export const getUserReservationsAction = () => {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      const res = await axios.get("/my-schedule");
      dispatch({ type: GET_USER_RESERVATIONS, payload: res.data });
      dispatch(asyncActionFinish());
    } catch (error) {
      console.log(error);
      dispatch(asyncActionError());
    }
  };
};

export const removeSelfFromReservationAction = (reservationId) => {
  return async (dispatch) => {
    console.log("remove self from reservation triggered", reservationId);
    try {
      dispatch(asyncActionStart());
      await axios.delete(`/my-schedule/${reservationId}/delete`);
      toastr.success("Réservation annulée");
      dispatch({ type: DELETE_SELF_FROM_RESERVATION, payload: reservationId });

      dispatch(asyncActionFinish());
    } catch (error) {
      console.log(error);
      dispatch(asyncActionError());
    }
  };
};

export const getFullScheduleAction = () => async (dispatch) => {
  console.log("get full schedule action triggered");
  try {
    dispatch(asyncActionStart());
    const res = await axios.get("/box/schedule");
    dispatch({ type: GET_FULL_SCHEDULE, payload: res.data });
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

export const deleteUserFromReservationAction = (
  reservationId,
  userId
) => async (dispatch) => {
  console.log(`remove user ${userId} from reservation ${reservationId}`);
  try {
    await axios.post(`/box/schedule/${reservationId}/delete`, { userId });
    dispatch({
      type: DELETE_USER_FROM_RESERVATION,
      payload: { reservationId, userId },
    });
    toastr.success("Membre supprimé de la réservation avec succès");
  } catch (error) {
    console.log(error);
    toastr.error(
      "Échec de la suppression du membre à la réservation",
      "Veuillez réessayer. Si le problème persiste, contactez un administrateur"
    );
  }
};
export const addUserToReservationAction = (
  reservationId,
  userId,
  self
) => async (dispatch) => {
  try {
    if (self) {
      console.log(`add self with ID ${userId} to reservation ${reservationId}`);
      await axios.post(`/box/schedule/${reservationId}/add`, { userId });
      dispatch({
        type: ADD_SELF_TO_RESERVATION,
        payload: { reservationId, userId },
      });
      toastr.success("Nouvelle réservation confirmée");
    } else {
      console.log(`add user ${userId} to reservation ${reservationId}`);
      await axios.post(`/box/schedule/${reservationId}/add`, { userId });
      dispatch({
        type: ADD_USER_TO_RESERVATION,
        payload: { reservationId, userId },
      });
      toastr.success("Membre ajouté à la réservation avec succès");
    }
  } catch (error) {
    console.log(error);
    toastr.error(
      "Échec de l'ajout du membre à la réservation",
      "Veuillez réessayer. Si le problème persiste, contactez un administrateur"
    );
  }
};

export const newReservationAction = (reservation, userId) => async (
  dispatch
) => {
  if (userId) {
    console.log("create reservation action triggered", reservation, userId);
    try {
      await axios.post("/schedule/new", { reservation, userId });
      dispatch({
        type: CREATE_NEW_RESERVATION,
        payload: {
          reservation: { ...reservation, date: toTimestamp(reservation.date) },
          userId,
        },
      });
      toastr.success("Membre ajouté à la réservation avec succès");
    } catch (error) {
      console.log(error);
      toastr.error(
        "Echec de la réservation",
        "Veuillez réessayer. Si le problème persiste, contactez un administrateur"
      );
    }
  } else {
    console.log("create self reservation action triggered", reservation);
    try {
      await axios.post("/schedule/new", reservation);
      dispatch({
        type: CREATE_NEW_RESERVATION_SELF,
        payload: { ...reservation, date: toTimestamp(reservation.date) },
      });
      toastr.success("Réservation confirmée");
    } catch (error) {
      console.log(error);
      toastr.error(
        "Echec de la réservation",
        "Veuillez réessayer. Si le problème persiste, contactez un administrateur"
      );
    }
  }
};
