import { createReducer } from "../../utils/reducerUtil";
import { OPEN_MODAL, CLOSE_MODAL } from "./modalsConstants";

const initialState = {
  open: false,
  modalComponent: null,
  modalHeader: null,
  modalType: null,
  confirmAction: null,
  formAction: null
};

export const openModal = (state, payload) => {
  return { ...state, open: true, ...payload };
};

export const closeModal = (state, payload) => {
  return { open: false, modalComponent: null, modalType: null };
};

export default createReducer(initialState, {
  [OPEN_MODAL]: openModal,
  [CLOSE_MODAL]: closeModal
});
