import React from "react";
import { connect } from "react-redux";
import BoxMessageDetailsModal from "../modals/messages/BoxMessageDetailsModal";
import {
  openModalAction,
  closeModalAction
} from "../../../redux/modals/modalsActions";
import { deleteBoxMessagesAction } from "../../../redux/messages/messagesActions";

const BoxMessageItem = ({
  message,
  last,
  date,
  openModalAction,
  closeModalAction,
  deleteBoxMessagesAction,
  user
}) => {
  const { messageId } = message;
  let modal, messageDate;
  if (window.innerWidth >= 900) {
    messageDate = date;
  } else {
    messageDate = date.substring(0, 5);
  }
  if (user && user.profileType === "box") {
    modal = {
      modalHeader: message.title,
      modalType: "delete",
      modalComponent: <BoxMessageDetailsModal message={message} />,
      confirmAction: () => {
        deleteBoxMessagesAction(messageId);
        closeModalAction();
      }
    };
  } else if (user && user.profileType === "athlete") {
    modal = {
      modalHeader: message.title,
      modalType: null,
      modalComponent: <BoxMessageDetailsModal message={message} />
    };
  }

  if (last) {
    return (
      <div className="box-message" onClick={() => openModalAction(modal)}>
        <p>
          {message.title} <span>{messageDate}</span>
        </p>
      </div>
    );
  } else {
    return (
      <div className="box-message" onClick={() => openModalAction(modal)}>
        <p>
          {message.title} <span>{messageDate}</span>
        </p>
      </div>
    );
  }
};

const mapState = (state, props) => ({
  user: state.user
});

const mapActions = {
  openModalAction,
  closeModalAction,
  deleteBoxMessagesAction
};

export default connect(mapState, mapActions)(BoxMessageItem);
