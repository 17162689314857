import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SideMenu from "./SideMenu";
import Profile from "./Profile";

/* Actions */
/* ------- */

const ResponsiveMenu = ({ open, user }) => (
  <React.Fragment>
    {open && (
      <div className="responsive-menu">
        <SideMenu user={user} />
        <Profile user={user} />
      </div>
    )}
  </React.Fragment>
);

ResponsiveMenu.defaultProps = {
  open: false
};
ResponsiveMenu.propTypes = {
  open: PropTypes.bool
};

const mapState = state => ({
  open: state.responsive.open,
  user: state.user
});

const actions = {};

export default connect(mapState, actions)(ResponsiveMenu);
