import React from "react";
// import PropTypes from 'prop-types'
import { connect } from "react-redux";
import ScheduleItem from "./ScheduleItem";
import { openModalAction } from "../../../redux/modals/modalsActions";
import NewReservationModal from "../modals/schedule/NewReservationModal";
import ReservationConfirmationModal from "../modals/schedule/ReservationConfirmationModal";
import ReservationDetailsModal from "../modals/schedule/ReservationDetailsModal";
import { removeSelfFromReservationAction } from "../../../redux/schedule/scheduleActions";
import { dayCapMin } from "../../../utils/daysUtil";

const ScheduleTableItem = ({
  date,
  userReservations,
  openModalAction,
  changeDate,
  user,
  activeReservations,
  workoutSchedule,
  workoutType,
  removeSelfFromReservationAction,
}) => {
  const todaysDate = new Date();
  todaysDate.setHours(0, 0, 0, 0);
  const endReservationDate = new Date();
  endReservationDate.setDate(endReservationDate.getDate() + 8);
  endReservationDate.setHours(0, 0, 0, 0);
  const modal = {
    modalHeader: "Nouvelle Réservation",
    modalComponent: <NewReservationModal date={date} />,
  };
  let reservationsArr = [];
  const day = dayCapMin[date.getDay()];

  if (user && user.profileType === "athlete") {
    // console.log('user.profileType = "athlete"');
    return (
      <div className="schedule-dashboard__table--column">
        <div className="schedule-dashboard__table--column--header">
          {`${day} ${date.toLocaleDateString("fr-FR").substring(0, 5)}`}
        </div>
        <div className="schedule-dashboard__table--column--workouts">
          {userReservations &&
            userReservations.map((e) => {
              const dateZero = new Date(0);
              dateZero.setUTCSeconds(e.date._seconds);
              const reservationDate = dateZero.toLocaleString("fr");
              const reservationEpoch = dateZero.setHours(0, 0, 0, 0);
              const reservationDay = new Date(reservationEpoch);
              if (reservationDay.valueOf() === date.valueOf()) {
                reservationsArr.push({ e, reservationDate });
              }
              return null;
            })}
          {reservationsArr.length > 0 &&
            reservationsArr.map((element) => {
              if (date < todaysDate) {
                return null;
              } else {
                return (
                  <ScheduleItem
                    key={element.e.reservationId}
                    wodType={element.e.workoutType}
                    wodName={element.e.workoutName}
                    date={element.reservationDate}
                    click={() =>
                      openModalAction({
                        modalHeader: "Annuler la réservation",
                        modalType: "cancel",
                        modalForm: false,
                        confirmAction: () =>
                          removeSelfFromReservationAction(
                            element.e.reservationId
                          ),
                        modalComponent: (
                          <ReservationConfirmationModal
                            reservation={element}
                            date={date}
                          />
                        ),
                      })
                    }
                  />
                );
              }
            })}
          {reservationsArr.length === 0 && date < todaysDate && (
            <ScheduleElement passed />
          )}
          {reservationsArr.length === 0 &&
            date >= todaysDate &&
            date < endReservationDate && (
              <ScheduleElement
                create
                openModal={() => openModalAction({ ...modal })}
              />
            )}
          {reservationsArr.length === 0 && date >= endReservationDate && (
            <ScheduleElement notAvailable />
          )}
        </div>
      </div>
    );
  } else if (user && user.profileType === "box") {
    return (
      <div className="schedule-dashboard__table--column">
        <div className="schedule-dashboard__table--column--header">
          {`${day} ${date.toLocaleDateString("fr-FR").substring(0, 5)}`}
        </div>
        <div className="schedule-dashboard__table--column--workouts">
          {/* {date < todaysDate && <ScheduleElement passed />}
          {date >= endReservationDate && <ScheduleElement notAvailable />} */}
          {/* {date >= todaysDate &&
            date < endReservationDate && */}
          {workoutSchedule &&
            Object.keys(workoutSchedule).map((wodType, i) => {
              if (wodType === workoutType) {
                const day = date.getDay();
                return workoutSchedule[wodType].times[day].map((time) => {
                  const hours = time.substring(0, 2);
                  const minute = time.substring(2, 4);
                  const dateTime = date;
                  dateTime.setHours(hours, minute, 0, 0);
                  const workoutDate = new Date(dateTime);
                  const dateString = workoutDate.toLocaleString("fr");

                  let activeResa;
                  activeReservations.map((resa) => {
                    const newDate = new Date(0);
                    newDate.setUTCSeconds(resa.date._seconds);

                    if (
                      workoutType === resa.workoutType &&
                      workoutDate.toLocaleString("fr") ===
                        newDate.toLocaleString("fr")
                    ) {
                      activeResa = resa;

                      return resa;
                    } else return null;
                  });
                  return (
                    <ScheduleItem
                      key={`${day}/${wodType}/${time}`}
                      wodType={wodType}
                      wodName={workoutSchedule[wodType].workoutName}
                      date={dateString}
                      resa={activeResa}
                      passed={workoutDate < new Date()}
                      click={() => {
                        openModalAction({
                          modalHeader: `${
                            workoutSchedule[wodType].workoutName
                          } du ${dateString
                            .split(" ")[0]
                            .substring(0, 5)} à ${dateString
                            .split(" ")[1]
                            .substring(0, 5)}
                          `,
                          modalComponent: (
                            <ReservationDetailsModal
                              resa={activeResa}
                              wodType={wodType}
                              wodName={workoutSchedule[wodType].workoutName}
                              newResa={{
                                capacity: workoutSchedule[wodType].capacity,
                                duration: workoutSchedule[wodType].duration,
                                waitlistCapacity:
                                  workoutSchedule[wodType].waitlistCapacity,
                                date: workoutDate,
                                workoutType: wodType,
                                workoutName:
                                  workoutSchedule[wodType].workoutName,
                              }}
                              date={dateString}
                            />
                          ),
                        });
                      }}
                    />
                  );
                });
              } else {
                return null;
              }
            })}
        </div>
      </div>
    );
  }
};

// ScheduleTableItem.propTypes = {}

const mapState = (state, props) => ({
  userReservations: state.schedule.userReservations,
  user: state.user,
  activeReservations: state.schedule.activeReservations,
  workoutSchedule: state.schedule.workoutSchedule,
});

const mapActions = {
  openModalAction,
  removeSelfFromReservationAction,
};

export default connect(mapState, mapActions)(ScheduleTableItem);

export const ScheduleElement = ({
  create,
  passed,
  notAvailable,
  closed,
  openModal,
}) => {
  return (
    <div className="schedule-dashboard__table--column--element">
      <div
        className="schedule-dashboard__table--column--element-icon"
        onClick={create && openModal}
      >
        {create && (
          <React.Fragment>
            <i className="fal fa-calendar-plus icon-green"></i>
            <p>Nouvelle Réservation</p>
          </React.Fragment>
        )}
        {passed && (
          <React.Fragment>
            <i className="fal fa-calendar-check icon-orange"></i>
            <p>Date passée</p>
          </React.Fragment>
        )}
        {notAvailable && (
          <React.Fragment>
            <i className="fal fa-calendar-times icon-orange"></i>
            <p>Réservation indisponible</p>
          </React.Fragment>
        )}
        {closed && (
          <React.Fragment>
            <i className="fal fa-calendar-exclamation icon-red"></i>
            <p>BOX FERMÉE</p>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};
